import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
  return (
    <div className="loader">
      <Spin fullscreen={false} size='small' tip="Researching..." />
    </div >
  )
}

export default Loader