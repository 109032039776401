import React from 'react'
import "./index.scss"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChartPie, faSliders } from '@fortawesome/free-solid-svg-icons'

const MoreOptions = () => {
    return (
        <div className='more-options-wrap'>
            <div className='container'>
                <h5>More Options</h5>
                <ul>
                    <li>
                        <Link to="/preferences-setup">
                            <FontAwesomeIcon icon={faSliders} fontSize={12} color='#4e4e4e' />
                            <span className='px-1'> Set your preferences </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/greenscore">
                            <FontAwesomeIcon icon={faChartPie} fontSize={12} color='#4e4e4e' />
                            <span className='px-1'>  Scoring method </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/glossary">
                            <FontAwesomeIcon icon={faBars} fontSize={12} color='#4e4e4e' />
                            <span className='px-1'> Dietary filters defined </span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MoreOptions