import { HTTPService } from "./service";

// GET PRODUCT DATA
export const getProductData = async ({ productName, mainSize }) => {
  const params = {};
  if (mainSize) {
    params.size = mainSize;
  }
  params.activegs = 0;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductData?productName=${productName}`,
    params: params,
  });
};

// GET OTHER PRODUCT DATA
export const getOtherProductData = async ({ productName, size }) => {
  const params = {};
  if (size) {
    params.size = size;
  }
  params.activegs = 0;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductOtherData?productName=${productName}`,
    params: params,
  });
};

// SUBMIT A BUG
export const submitBug = async (data) =>
  await HTTPService({
    method: "POST",
    url: `/UserBugReports`,
    data: data,
  });

//PDP FAQ
export const pdpFaq = async (productCode) =>
  await HTTPService({
    url: `/product_faqs/?filter={"where": {"productCode":"${productCode}"}}`,
    method: "GET",
  });

//CHECK BRAND//
export const brandClaimStatus = async (brand) =>
  await HTTPService({
    url: `/product_brands?filter[where][brand_name]=${brand}`,
    method: "GET",
  });

//ALTERNATIVE PRODUCTS//
export const alternativeProducts = async ({ productNameURL, client, userLocation }) => {
  const queryParams = {
    productName: productNameURL,
    limit: 20,
    client: client
  };

  if (userLocation !== undefined) {
    queryParams.location = userLocation;
  }

  // Check data first
  // if (preferences) {
  //   if (preferences?.diets && preferences?.diets.length > 0) {
  //     const dietIDs = preferences?.diets.map(diet => diet.id).join(',');
  //     queryParams.diets = dietIDs;
  //   }

  //   if (preferences?.allergies && preferences?.allergies.length > 0) {
  //     const allergyIDs = preferences?.allergies.map(allergy => allergy.id).join(',');
  //     queryParams.allergies = allergyIDs;
  //   }

  //   if (preferences?.selectedEnvironment !== undefined) {
  //     queryParams.selectedEnvironment = preferences?.selectedEnvironment;
  //   }
  //   if (preferences?.selectedFoodSafety !== undefined) {
  //     queryParams.selectedFoodSafety = preferences?.selectedFoodSafety;
  //   }
  //   if (preferences?.selectedNutrition !== undefined) {
  //     queryParams.selectedNutrition = preferences?.selectedNutrition;
  //   }
  //   if (preferences?.selectedProcessing !== undefined) {
  //     queryParams.selectedProcessing = preferences?.selectedProcessing;
  //   }
  // }

  try {
    const response = await HTTPService({
      method: "GET",
      url: "/ProductTableMergeds/getRecommndedProducts",
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching alternative products:", error);
    throw error;
  }
};


//SIMILAR PRODUCTS
export const similarProducts = async ({ productNameURL, client, userLocation, filtersParam }) => {
  const queryParams = {
    productName: productNameURL,
    limit: 20,
    client: client,
    ...filtersParam
  };

  if (userLocation !== undefined) {
    queryParams.location = userLocation;
  }

  try {
    const response = await HTTPService({
      method: "GET",
      url: "/ProductTableMergeds/getRelatedProducts",
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
};

//get all diets
// export const getDiets = async () =>
//   await HTTPService({
//     method: "GET",
//     url: "/Diets",
//   });

//get all allergies
// export const getAllergies = async () =>
//   await HTTPService({
//     method: "GET",
//     url: "/Allergies",
//   });

// GET BETTER-FOR-YOU OPTIONS
export const getBFY = async ({ upcCode, client, userLocation, filtersParam }) => {
  const queryParams = {
    upcCode: upcCode,
    client: client,
    location: userLocation,
    limit: 20,
    ...filtersParam
  };

  // Check data first
  // if (preferences) {
  //   if (preferences?.diets && preferences?.diets.length > 0) {
  //     const dietIDs = preferences?.diets.map(diet => diet.id).join(',');
  //     queryParams.diets = dietIDs;
  //   }

  //   if (preferences?.allergies && preferences?.allergies.length > 0) {
  //     const allergyIDs = preferences?.allergies.map(allergy => allergy.id).join(',');
  //     queryParams.allergies = allergyIDs;
  //   }

  //   if (preferences?.selectedEnvironment !== undefined) {
  //     queryParams.selectedEnvironment = preferences?.selectedEnvironment;
  //   }
  //   if (preferences?.selectedFoodSafety !== undefined) {
  //     queryParams.selectedFoodSafety = preferences?.selectedFoodSafety;
  //   }
  //   if (preferences?.selectedNutrition !== undefined) {
  //     queryParams.selectedNutrition = preferences?.selectedNutrition;
  //   }
  //   if (preferences?.selectedProcessing !== undefined) {
  //     queryParams.selectedProcessing = preferences?.selectedProcessing;
  //   }
  // }

  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getBetterForYouProductsUPC`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
}