import React from "react";
import { Helmet } from "react-helmet";
import banner from "../../Assets/Images/gccalculate-banner.png";
import gsSlide from "../../Assets/Images/greenscore-standards-slide.png";
import USDA from "../../Assets/Images/USDA.png";
import WHO from "../../Assets/Images/WHO.png";
import FDA from "../../Assets/Images/fda.png";
import NIH from "../../Assets/Images/NIH.png";
import EPA from "../../Assets/Images/EPA.png";
import apple from "../../Assets/Images/Svgs/apple.svg";
import dropper from "../../Assets/Images/Svgs/dropper.svg";
import guard from "../../Assets/Images/Svgs/guard.svg";
import mountain from "../../Assets/Images/Svgs/mountain.svg";
import ResearchInstiture from "../../Assets/Images/Research-institute.png";
import green from "../../Assets/Images/Svgs/green.svg";
import yellow from "../../Assets/Images/Svgs/yellow.svg";
import red from "../../Assets/Images/Svgs/red.svg";
import lightGreen from "../../Assets/Images/Svgs/light-green.svg";
import veryLightGreen from "../../Assets/Images/Svgs/very-light-green.svg";
import darkYellow from "../../Assets/Images/Svgs/dark-yellow.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import "./index.scss";

const Greenscore = () => {
  return (
    <>
      <Helmet>
        <title>
          GreenScore® Nutrition & Sustainability Food Ratings | GreenChoice
        </title>
        <meta
          name="description"
          content="GreenScore® ratings use science-backed standards to evaluate food products' health and climate impacts. Check GreenScores® for 360K+ foods!"
        />
        <meta
          name="title"
          content="GreenScore® Nutrition & Sustainability Food Ratings | GreenChoice"
        />
      </Helmet>
      <div id="greenscore">
        <div className="banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <p className="mb-3">GreenChoice GreenScore® rating method </p>
                <h1 className="mb-2 primary-color fw-bold">
                  How do we GreenScore® products?
                </h1>
                <p>
                  Our GreenScore® product rating uses science-backed standards
                  to evaluate food and beverage products’ health and
                  sustainability impacts.
                </p>
                <p>
                  Products are scored across four categories: (1) nutritional
                  value, (2) level of processing, (3) food safety, and (4)
                  environmental footprint. Within each category, products are
                  scored from 1 to 100. A product’s total GreenScore® is the
                  average of its four category scores, with each category
                  weighted equally.
                </p>
                <p>
                  When we suggest products to you, we use your GreenScore®
                  category weights to identify products that match your personal
                  values well.
                </p>
              </div>
              <div className="col-md-6">
                <LazyLoadImage effectt="blur" className="img-fluid" src={banner} alt="greenscore" />
              </div>
            </div>
          </div>
        </div>

        <section id="scoresSection">
          <div className="container common-padding">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  className="img-fluid mb-3"
                  src={apple}
                  alt="Nutrition Score"
                />
                <h2 className="fw-bold mb-3">Nutrition Score</h2>
                <p className="gs-content">
                  The Food Nutrition Score is based on the latest USDA Dietary Guidelines for Americans, which provides nutrition recommendations for the general U.S. population. Each food product is evaluated based on (i) the key ‘beneficial nutrients’ it supplies, (ii) the amount of ‘nutrients to limit’ it contains, and (iii) whether it provides healthful foods such as fruits, vegetables, nuts, seeds, and legumes. The more nutritious a product is, the higher the food will score.
                </p>
              </div>
              <div className="col-md-12">
                <ul className="scores">
                  <li>
                    <img src={green} alt="Very high nutritional value" />
                    <p>70 – 100</p>
                    <span> Very high nutritional value </span>
                  </li>
                  <li>
                    <img src={veryLightGreen} alt="High nutritional value" />
                    <p>60 – 69</p>
                    <span> High nutritional value </span>
                  </li>
                  <li>
                    <img src={yellow} alt="Some nutritional value" />
                    <p>50 – 59</p>
                    <span> Some nutritional value </span>
                  </li>
                  <li>
                    <img src={red} alt="Some nutritional value " />
                    <p>0 – 49</p>
                    <span> Low nutritional value </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container common-padding">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  className="img-fluid mb-3"
                  src={dropper}
                  alt="Processing Score "
                />
                <h2 className="fw-bold mb-3"> Processing Score </h2>
                <p className="gs-content">
                  The Food Processing Score is based on NOVA, a widely used system that classifies foods based on their level of processing. Each food product is evaluated based on the degree of processing it has undergone, specifically (i) the processing methods used and (ii) whether it contains natural or artificial sweeteners, flavors, colors, or other additives. The closer a product is to its natural state, the higher the product will score.
                </p>
              </div>
              <div className="col-md-12">
                <ul className="scores">
                  <li>
                    <img src={green} alt="Unprocessed or minimally processed" />
                    <p> 90 – 100 </p>
                    <span> Unprocessed or minimally processed </span>
                  </li>
                  <li>
                    <img src={yellow} alt="High nutritional value" />
                    <p> 50 – 89 </p>
                    <span> Moderately processed </span>
                  </li>
                  <li>
                    <img src={red} alt="Highly processed" />
                    <p> 0 – 49 </p>
                    <span> Highly processed </span>
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <div className="container common-padding">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  className="img-fluid mb-3"
                  src={guard}
                  alt="Food Safety Score"
                />
                <h2 className="mb-3 fw-bold"> Food Safety Score </h2>
                <p className="gs-content">
                  The Food Safety Score evaluates products for (i) the presence of additives with known health concerns based on data from the Joint FAO/WHO Expert Committee on Food Additives (JECFA) and (ii) the potential pesticide and herbicide exposure based on the USDA Pesticide Data Program (PDP) along with the associated degree of risk according to WHO guidelines for classifying pesticides by hazard. The lower the total estimated food safety risk, the higher the product will score.
                </p>
              </div>
              <div className="col-md-12">
                <ul className="scores">
                  <li>
                    <img src={green} alt="No health risk" />
                    <p> 100 </p>
                    <span> No ingredient concerns </span>
                  </li>
                  <li>
                    <img src={lightGreen} alt="Low health risk" />
                    <p> 75 – 99 </p>
                    <span> Minimal ingredient concerns </span>
                  </li>
                  <li>
                    <img src={yellow} alt="Moderate health risk" />
                    <p> 50 – 74 </p>
                    <span> Moderate ingredient concerns </span>
                  </li>
                  <li>
                    <img src={darkYellow} alt="Significant health risk" />
                    <p> 25 – 49 </p>
                    <span> Some ingredient concerns </span>
                  </li>
                  <li>
                    <img src={red} alt="Very significant health risk" />
                    <p> 0 – 24 </p>
                    <span> Significant ingredient concerns </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container common-padding">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  className="img-fluid mb-3"
                  src={mountain}
                  alt="Environment Score"
                />
                <h2 className="mb-3 fw-bold"> Climate Footprint Score </h2>
                <p className="gs-content">
                  The Climate Footprint Score is based on the Greenhouse Gas (GHG) Product Life Cycle Accounting and Reporting Standard. Each product is evaluated based on its (i) estimated carbon footprint per serving and (ii) its estimated water footprint per serving. The carbon footprint considers the greenhouse gas emissions (measured as carbon dioxide equivalents) emitted throughout the lifecycle of each ingredient from farm to farm gate. The water footprint considers the blue, green, and grey water used throughout the lifecycle of each ingredient from farm to farm gate. The lower the estimated carbon and water footprints, the higher a product will score.
                </p>
              </div>
              <div className="col-md-12">
                <ul className="scores">
                  <li>
                    <img src={green} alt="Very low  footprint" />
                    <p> 95 – 100 </p>
                    <span> Very Low footprint </span>
                  </li>
                  <li>
                    <img src={lightGreen} alt="Low footprint" />
                    <p> 90 – 94 </p>
                    <span> Low footprint </span>
                  </li>
                  <li>
                    <img src={veryLightGreen} alt="Moderate footprint" />
                    <p> 65 – 89 </p>
                    <span> Moderate footprint </span>
                  </li>
                  <li>
                    <img src={yellow} alt="High footprint" />
                    <p> 30 – 64 </p>
                    <span> High footprint </span>
                  </li>
                  <li>
                    <img src={darkYellow} alt="Very high footprint" />
                    <p> 6 – 29 </p>
                    <span> Very high footprint </span>
                  </li>
                  <li>
                    <img src={red} alt="Extremely high footprint" />
                    <p> 0 – 5 </p>
                    <span> Extremely high footprint </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="rating-section">
          <div className="container common-padding">
            <div>
              <h2 className="mb-3"> GreenScore® Standards </h2>
              <p>
                Our GreenScore® ratings break down food products’ impact on your
                health & the environment, using science-backed standards and
                data from research institutes/NGOs, government agencies/IGOs,
                peer-reviewed journals, and manufacturers.
              </p>
              <img className="img-fluid" src={gsSlide} alt="gs-slide" />
            </div>
          </div>
        </div>

        <div className="container common-padding">
          <div>
            <h2 className="mb-3">GreenScore® Data Sources</h2>
            <p>
              The GreenScore® product rating considers research and data from
              140+ vetted sources, including the following organizations:
            </p>
            <div className="data-sources">
              <a href="https://www.usda.gov/" target="_blank" rel="noreferrer" ><img className="img-fluid" src={USDA} alt="USDA" /></a>
              <a href="https://www.who.int/" target="_blank" rel="noreferrer" ><img className="img-fluid" src={WHO} alt="WHO" /></a>
              <a href="https://www.fda.gov/" target="_blank" rel="noreferrer" ><img className="img-fluid" src={FDA} alt="FDA" /></a>
              <a href="https://www.nih.gov/" target="_blank" rel="noreferrer" ><img className="img-fluid" src={NIH} alt="NIH" /></a>
              <a href="https://www.epa.gov/" target="_blank" rel="noreferrer" ><img className="img-fluid" src={EPA} alt="EPA" /></a>
              <a href="" target="_blank" rel="noreferrer" >
                <img
                  className="img-fluid"
                  src={ResearchInstiture}
                  alt="Research-institute"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="container common-padding">
          <div className="mb-5">
            <h2 className="mb-3">Disclosure from GreenChoice</h2>
            <p>
              We’re constantly evolving the GreenScore® food product rating as
              new research emerges and trustworthy data becomes available.{" "}
            </p>
            <p>
              <strong>
                No company can pay to have their GreenScore® rating changed.
              </strong>
            </p>
            <p>
              The descriptions used to describe each rating tier constitute the
              opinion of GreenChoice. They only qualify the food ratings
              according to the GreenScore® rating method. These descriptions do
              not refer directly to the product’s quality. The information
              provided is for informational purposes only. Although we aim to
              provide accurate and up-to-date information, no guarantee is made
              to that effect.
            </p>
            <p>
              The app does not offer medical advice, or attempt to diagnose or
              treat any health problem, disease, or dietary condition. The
              information contained herein should not be used as a substitute
              for the advice of an appropriately qualified and licensed
              physician or other healthcare provider.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Greenscore;
