import React from 'react'
import './index.scss'
import ToggleFilter from '../ToggleFilter'
import { useFilters } from '../../Context/FiltersContext'

const AccordionFilter = ({ filters, name }) => {
    const { selectedFilters } = useFilters()
    const countArr = Object.keys(selectedFilters)
    const selectedFilterCount = countArr ? countArr.filter(item => item.split("_")[0] === name.toLowerCase()) : []
    return (
        <div className="accordion-item mb-3">
            <h2 className="accordion-header" id={"heading" + name}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + name} aria-expanded="false" aria-controls={"collapse" + name}>
                    {name} <span className='count ms-1'>{selectedFilterCount.length > 0 ? `(${selectedFilterCount.length})` : null}</span>
                </button>
            </h2>
            <div id={"collapse" + name} className="accordion-collapse collapse" aria-labelledby={"heading" + name} data-bs-parent="#accordionFilter">
                <div className="accordion-body">
                    <div className="row">
                        {
                            filters.map(filter => <ToggleFilter key={filter?.id} filter={filter} category={name} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccordionFilter