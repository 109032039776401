export const valueFilters = [{
    id:1,
    title: 'Highly nutritious',
    content: 'Only includes products with high to very high nutritional value. These products have a Nutritional Value rating of 60 or above.'
},
{
    id:2,
    title: 'Climate-friendly',
    content: 'Only includes products with low to very low carbon footprint and/or a low to very low water footprint. Excludes products with more than a moderate carbon footprint or water footprint.'
},{
    id:3,
    title: 'Real ingredients',
    content: 'Only includes products made with real-food ingredients you can find in household kitchens, meaning no added industrial flavors, colors, or texture modifiers whatsoever.'
},{
    id:4,
    title: 'Organic',
    content: 'Only includes products made with organic ingredients; may not be certified organic.'
},{
    id:5,
    title: 'Plant-based',
    content: 'Only includes vegan products, made without meat, seafood, dairy, eggs, honey, or any other products made from animals or insects.'
}]

export const dietFilters = [{
    id:6,
    title: 'Vegan',
    content: 'No meat, seafood, dairy, eggs, honey, or any other products made from animals or insects.'
},
{
    id:7,
    title: 'Vegetarian',
    content: 'No meat, seafood, or any products made with these but may include dairy or eggs.'
},{
    id:8,
    title: 'Keto',
    content: 'Products very low in carbohydrates (less than 7 grams of carbs per serving).'
},{
    id:9,
    title: 'Paleo',
    content: 'Includes products made with foods believed to be available to early humans in paleolithic era only, such as most fruits and vegetables, nuts, seeds, meat, fish, and eggs.'
},{
    id:10,
    title: 'Gluten-free',
    content: 'No products containing the protein gluten in any form; may not be certified gluten-free.'
},{
    id:11,
    title: 'Pescatarian',
    content: 'No meat, but may include seafood, dairy, or eggs.'
},{
    id:12,
    title: 'Low FODMAP',
    content: 'No products high in fermentable oligosaccharides, disaccharides, monosaccharides, and polyols (FODMAPs).'
}]


export const foodAllergyFilters = [{
    id:13,
    title: 'No milk',
    content: 'Excludes products containing milk in any form.'
},{
    id:14,
    title: 'No eggs',
    content: 'Excludes products containing eggs in any form.'
},{
    id:15,
    title: 'No peanuts',
    content: 'Excludes products containing peanuts in any form.'
},{
    id:16,
    title: 'No tree nuts',
    content: 'Excludes products containing tree nuts in any form.'
},{
    id:17,
    title: 'No fish',
    content: 'Excludes products containing fish in any form.'
},{
    id:18,
    title: 'No shellfish',
    content: 'Excludes products containing shellfish in any form.'
},{
    id:19,
    title: 'No soy',
    content: 'Excludes products containing soy in any form.'
},{
    id:20,
    title: 'No wheat',
    content: 'Excludes products containing wheat in any form.'
},{
    id:21,
    title: 'No tomato',
    content: 'Excludes products containing tomato in any form.'
},{
    id:22,
    title: 'No corn',
    content: 'Excludes products containing corn in any form.'
},{
    id:23,
    title: 'No melon',
    content: 'Excludes products containing melon in any form.'
},{
    id:24,
    title: 'No lactose',
    content: 'Excludes products containing the sugar lactose in any form.'
},{
    id:25,
    title: 'No gluten',
    content: 'Excludes products containing the protein gluten in any form; may not be certified gluten-free.'
},{
    id:26,
    title: 'No sesama',
    content: 'Excludes products containing sesame in any form.'
},{
    id:27,
    title: 'No coconut',
    content: 'Excludes products containing coconut in any form.'
},{
    id:28,
    title: 'No almond',
    content: 'Excludes products containing almonds in any form.'
},{
    id:29,
    title: 'No sulfites',
    content: 'Excludes products containing added sulfites in any form.'
},{
    id:30,
    title: 'No chia',
    content: 'Excludes products containing chia in any form.'
},{
    id:31,
    title: 'No stevia',
    content: 'Excludes products containing stevia in any form.'
},{
    id:32,
    title: 'No sodium benzoate',
    content: 'Excludes products containing sodium benzoate in any form.'
}]

export const nutritionFilters = [{
    id:33,
    title: 'Low sodium',
    content: 'Products with less than 140 mg of sodium per serving — or less than 6% of the recommended Daily Value (DV) per serving.'
},{
    id:34,
    title: 'Low cholesterol' ,
    content: 'Products with less than 20 mg of cholesterol per serving — or less than around 7% of the recommended Daily Value (DV) per serving.'
},{
    id:35,
    title: 'Low saturated fat',
    content: 'Products with less than 1 gram of saturated per serving — or less than 5% of the recommended Daily Value (DV) per serving'
},{
    id:36,
    title:'Low carbohydrate',
    content: 'Products with less less than 15 grams of carbs per serving — or less than 5% of the recommended Daily Value (DV) per serving.'
},{
    id:37,
    title: 'Low fiber',
    content: 'Products with less than 1.5 grams of fiber per serving — or less than 5% of the recommended Daily Value (DV) per serving.'
},{
    id:38,
    title: 'High potassium',
    content:'Products with more than 940 mg of potassium per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
    id:39,
    title: 'High calcium',
    content:'Products with more than 260 mg of calcium per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
    id:40,
    title: 'High iron',
    content:'Products with more than 3.5 mg or iron per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
    id:41,
    title: 'High vitamin C',
    content:'Products with more than 18 mg of vitamin C per serving — or more than 20% of the recommended Daily Value (DV) per serving.'

},{
    id:42 ,
    title: 'High vitamin D',
    content:'Products with more than 4 mcg of vitamin D per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
    id:43,
    title: 'High vitamin A',
    content:'Products with more than 180 mcg of vitamin A per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
    
},{
     id:44,
    title: 'High omega-3',
    content:'Products with more than 1.6 grams of omega-3 fatty acids per serving — or 20% of recommended daily Adequate Intake (AI) per serving.'

},{
     id:45,
    title: 'High carbohydrate',
    content:'Products with more with more than 55 grams of carbs per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
     id:46,
    title: 'High fiber',
    content:'Products with more than 5.5 grams of fiber per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
},{
    id:47,
    title: 'High protein',
    content:'Products with more more than 10 grams of protein per serving — or more than 20% of the recommended Daily Value (DV) per serving.'
}
]

export const ingredientsFilter = [{
    id:48,
    title:"No added sugar",
    content:"Excludes products containing added sugar."
}, {
    id:49,
    title:"No artificial colors",
    content:"Excludes products containing synthetic color additives."
},{
    id:50,
    title:" No artificial flavors",
    content:"Excludes products containing synthetic flavor additives."
},{
    id:51,
    title:"No artificial preservatives",
    content:"Excludes products containing synthetic additives used to prevent spoilage and extend shelf-life."
},{
    id:52,
    title:"No artificial sweeteners",
    content:"Excludes products containing synthetic sugar substitutes."
},{
    id:53,
    title:"No nitrates",
    content:"Excludes products containing added nitrates and nitrites."
},{
    id:54,
    title:"No MSG",
    content:"Excludes products containing sodium glutamate."
},{
    id:55,
    title:"No carrageenan",
    content:"Excludes products containing carrageenan."
},{
    id:56,
    title:"No sulfites",
    content:"Excludes foods containing added sulfites."
},{
    id:57,
    title:"100% whole grain",
    content:"Products that are 100% whole grain."
},{
    id:58,
    title:"Contains whole grains",
    content:"Products containing one or more whole grains as a major ingredient."
},{
    id:59,
    title:"Plant-based ingredients",
    content:"Contains fruits, vegetables, nuts, or legumes as major ingredients."
}
]

export const sustainFilters = [{
    id:60,
    title:"Minimally processed",
    content:"Only includes unprocessed or minimally processed products."
},{
    id:61,
    title:"Carbon footprint",
    content:"Filter for products ranging from very low to extremely high carbon footprints (estimated)."
},{
    id:62,
    title:"Water footprint",
    content:"Filter for products ranging from very low to extremely high water footprints (estimated)."
},{
    id:63,
    title:"Organic ingredients",
    content:"Only includes products made with organic ingredients; may not be certified organic."
},{
    id:64,
    title:"No palm oil",
    content:"Excludes products that contain palm oil."
}
]