import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { getBFY } from '../../APIs/functions';
import ProductCarousel from '../ProductCarousel';
import './index.scss'
import { NameContext } from '../../Context/NameContext';
import FilterSelector from '../FilterSelector';
import { useFilters } from '../../Context/FiltersContext';
import { Spin } from 'antd';

const Alternative = ({ product }) => {
  const { selectedFilters } = useFilters()
  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const client = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  let clientParam = ""

  if (client === "" || client === "qrdemo" || client === undefined) {
    clientParam = ""
  } else {
    clientParam = client
  }

  const [filtersParam, setFiltersParams] = useState({
    diets: "",
    allergies: "",
    valueFilters: "",
    ingredientFilters: "",
    sustainabilityFilters: "",
    nutrientFilters: ""
  })
  const [filtersReady, setFiltersReady] = useState(false);

  // const [isRefferer, setIsRefferer] = useState(false);

  // useEffect(() => {
  //   const referringSite = document.referrer;
  //   if (referringSite.includes('https://marquette.storebyweb.com/')) {
  //     setIsRefferer(true);
  //   }
  // }, []);

  function getLocationIdParam(url) {
    let locationIdParam = url.searchParams.get('location_id');

    if (locationIdParam) {
      locationIdParam = locationIdParam.split('?')[0];
    }

    return locationIdParam;
  }

  const userLocation = getLocationIdParam(url);

  const { productNameURL, upcCode } = product
  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
  }

  const { name } = useContext(NameContext)

  useEffect(() => {
    let allDiets = [];
    let allAllergies = [];
    let allValues = [];
    let allIngredients = [];
    let allSustainabilities = [];
    let allNutrients = [];

    // Extract all filter types if present in filters, else default to empty
    Object.keys(selectedFilters).forEach(item => {
      if (item.includes("diet")) {
        allDiets.push(item.split("_")[1]);
      }
      if (item.includes("allerg")) {
        allAllergies.push(item.split("_")[1]);
      }
      if (item.includes("value")) {
        allValues.push(item.split("_")[1]);
      }
      if (item.includes("ingredient")) {
        allIngredients.push(item.split("_")[1]);
      }
      if (item.includes("sustainability")) {
        allSustainabilities.push(item.split("_")[1]);
      }
      if (item.includes("nutrient")) {
        allNutrients.push(item.split("_")[1]);
      }
    });

    // Update the filtersParam state with joined values or empty strings
    setFiltersParams({
      diets: allDiets.join(","),
      allergies: allAllergies.join(","),
      valueFilters: allValues.join(","),
      ingredientFilters: allIngredients.join(","),
      sustainabilityFilters: allSustainabilities.join(","),
      nutrientFilters: allNutrients.join(",")
    });

    setFiltersReady(true);

    return () => {
      setFiltersReady(false)
      setFiltersParams({
        diets: "",
        allergies: "",
        valueFilters: "",
        ingredientFilters: "",
        sustainabilityFilters: "",
        nutrientFilters: ""
      })
    }

  }, [selectedFilters]);

  // const { data: alternateProducts, isLoading: alternateProductsLoading, isError: alternateProductsError } = useQuery(['alternateProducts', productNameURL, client, userLocation], () => alternativeProducts({ productNameURL, client, userLocation, preferences }), queries)

  const { data: bfyData, isLoading: bfyLoading, isError: bfyError } = useQuery(['BFYData', upcCode, clientParam, userLocation, filtersParam], () => getBFY({ upcCode, clientParam, userLocation, filtersParam }), { ...queries, enabled: filtersReady })

  // const products = (!alternateProductsLoading &&
  //   !alternateProductsError &&
  //   Array.isArray(alternateProducts) ? alternateProducts : alternateProducts?.productRecommendedData
  // );

  const products = Array.isArray(bfyData) ? bfyData : (bfyData?.productRecommendedData || []);

  if (!bfyLoading && !bfyError && products.length === 0) {
    return null
  }

  return (
    <>
      {/* {bfyLoading || alternateProductsLoading ?
        <div className='flex align-items-center text-center justify-content-center my-5 mx-auto w-100'>
          <Spin />
        </div>
        : <Fragment>
          {isRefferer ?
            !bfyLoading && productWithImages.length > 0 && <section id="alternatives">
              <div className='container overflow-hidden'>
                <div className='text-center alternative-title'>
                  <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name}</span> </h2>
                </div >
                <ClientProductCarousel carousel={'alternative'} items={bfyData} />
              </div>
            </section>
            : !alternateProductsLoading && productWithImages.length > 0 && <section id="alternatives">
              <div className='container overflow-hidden'>
                <div className='text-center alternative-title'>
                  <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name}</span> </h2>
                </div >
                <ProductCarousel carousel={'alternative'} items={productWithImages} />
              </div>
            </section>}
        </Fragment>} */}
      {!bfyLoading ? <section id="alternatives">
        <div className='container overflow-hidden'>
          <div className='text-center alternative-title'>
            <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name === "qrdemo" ? "Store Name" : name}</span> </h2>
          </div >

          <FilterSelector />

          <ProductCarousel carousel={'alternative'} items={products} />
        </div>
      </section> :
        <section id="alternatives">
          <div className='container overflow-hidden'>
            <div className='text-center alternative-title'>
              <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name === "qrdemo" ? "Store Name" : name}</span> </h2>
            </div >

            <FilterSelector />
            <div className="d-flex justify-content-center align-items-center text-center h-100 py-5">
              <Spin fullscreen={false} size="small" />
            </div>
          </div>
        </section>}
    </>
  )
}

export default Alternative