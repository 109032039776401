import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../Assets/Images/Svgs/logo-white.svg'
import './index.scss'
const Footer = ({ disabled }) => {
  return (
    <>
      {!disabled && <footer className="footer">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between footer-content">
            <img loading="lazy" src={logo} alt="greenchoice-logo" />
            <div className='footer-links'>
              <Link to="/greenscore"> Scoring method </Link>
              <Link to="/glossary"> Dietary filters defined </Link>
            </div>
          </div>
        </div>
      </footer>}
    </>
  )
}

export default Footer