import React from "react";
import { Helmet } from "react-helmet";
import './index.scss'

const Terms = () => {
  return (
   <>
      <Helmet>
         <title>
          Terms of Use | GreenChoice
        </title>
        <meta
          name="description"
          content="These Terms of Use govern your use of and access to GreenChoice. Please take a moment to review these Terms before using GreenChoice."
        />
        <meta
          name="title"
          content="Terms of Use | GreenChoice"
        />
      </Helmet>
      <main>
        <div className="container">
          <section id="terms-and-conditions">
            <div className="terms-wrap">
              <h1 className="fw-bolder"> Terms of service </h1>
              <p>Last Updated: December 12, 2020</p>
              <p className="pt-4">
                <strong>Welcome to the GreenChoice Community!</strong> These Terms
                of Use govern your use of and access to the GreenChoice Services.
                By accessing or using the GreenChoice Services, or by accepting
                these Terms of Use, whether by clicking or any other method we
                specify, you agree to these Terms of Use. If you violate or do not
                agree to these Terms of Use, your access to and use of the
                GreenChoice Services is unauthorized.{" "}
              </p>
              <p>
                IMPORTANT: BY USING THE GREENCHOICE SERVICES, YOU AGREE TO RESOLVE
                ANY DISPUTE WITH GREENCHOICE THROUGH BINDING ARBITRATION, NOT IN
                COURT, AND YOU WAIVE CERTAIN RIGHTS TO PARTICIPATE IN CLASS
                ACTIONS. CERTAIN EXCEPTIONS AND WAIVERS ARE DETAILED IN SECTIONS
                13 AND 14.
              </p>
              <p>
                In these Terms of Use: When we say “GreenChoice”, we mean
                GreenChoice, PBC and any subsidiaries and affiliates of
                GreenChoice (including any that GreenChoice may form or acquire in
                the future). We also refer to GreenChoice as “we”, “us” or “our” –
                we hope these Terms of Use read better when we do that.
              </p>
              <p>
                When we say “GreenChoice Services”, we mean GreenChoiceNow.com,
                any GreenChoice mobile app(s), and all related services, content,
                functionality and transactions offered by GreenChoice on or
                through GreenChoiceNow.com and the GreenChoice app(s).
              </p>
              <p>
                When we say “you” or “your”, we mean users of and visitors to the
                GreenChoice Services (including you). When we say “Members”, we
                mean users of the GreenChoice Services who have registered for
                GreenChoice accounts (including you, if you’ve done that).
              </p>
              <p>
                When we say “Terms of Use”, we mean these Terms of Use and all
                other terms and policies on the GreenChoice Services (including
                updates we send or post). Over time, we’ll continue to update
                these Terms of Use – we explain that in section 17.
              </p>
            </div>
            <ol>
              <li>
                <h2>Member Account Registration</h2>
                <p>
                  To save your dietary preferences, build a shopping list, view
                  your past orders, and track your carbon footprint offset on
                  GreenChoice, you must become a Member by registering for an
                  account with the GreenChoice Services. You must be at least 18
                  years old to register for an account. You are limited to one
                  account per individual.
                </p>
                <p>
                  You agree that all registration information you provide us will
                  be accurate and complete, and you agree to keep your account
                  information current and up-to-date. You agree to keep your
                  GreenChoice username and password confidential. You will be
                  responsible and liable for all purchases made, and anything else
                  that occurs, through your account.
                </p>
                <p>
                  Please notify us immediately of any suspected unauthorized
                  activity on your account by sending an email to
                  community@greenchoicenow.com
                </p>
              </li>
  
              <li>
                <h2>Placing an Order on GreenChoice</h2>
                <p>
                  We work with 72Lux Inc DBA “Shoppable” to allow you to shop
                  popular stores GreenChoice-style.
                </p>
                <p>
                  {" "}
                  By using GreenChoice, you can easily filter by your dietary
                  needs and values, compare products’ impact on your health and
                  the environment, and order carbon-neutral from the stores listed
                  on GreenChoice Services.
                </p>
  
                <p>
                  Although you are able to find information about and purchase
                  products using GreenChoice Services, we are not the merchant of
                  record (the stores you order from are), nor are we a party to
                  any transaction you make with these stores. Accordingly, we
                  shall have no liability to any party in connection with such
                  transactions.
                </p>
  
                <p>
                  a. Shipping. All stores listed on GreenChoice offer standard
                  shipping to all 48 continental states, plus the District of
                  Columbia. Some of the store’s offer 2-day shipping on eligible
                  orders, but at checkout you will always be shown an estimated
                  delivery date of seven days from the order date. All stores
                  offer extended shipping to Alaska, Hawaii, and Puerto Rico, for
                  an additional charge. We offset the carbon emissions associated
                  with shipping your orders.
                </p>
                <p>
                  b. Payment Authorization. You authorize GreenChoice (and the
                  stores you’re ordering from using GreenChoice Services) to
                  charge all sums for orders that you make to the payment method
                  designated on our checkout page. If there is a problem charging
                  your selected payment method, we may charge any other valid
                  payment method associated with your account. GreenChoice
                  reserves the right to request additional information from you if
                  we have reason to believe, in our sole discretion, that a
                  payment method may be fraudulent.
                </p>
  
                <p>
                  c. Prices & Fees. You understand that GreenChoice reserves the
                  right to change its prices at any time, at its discretion.
                  GreenChoice may change the fees for our Service as we deem
                  necessary or appropriate for our business, including but not
                  limited to delivery fees and service fees.
                </p>
  
                <p>
                  d. Refunds and Credits. All returns will go through the store
                  who shipped you the product(s). Return policies through
                  GreenChoice match the return policy on the store’s site.
                  Generally most stores accept returns within 30 days with
                  exception of final sale merchandise or certain categories. For
                  more information regarding a specific store’s return policy or a
                  specific product please contact us at
                  community@greenchoicenow.com; we’re more than happy to help
                  point you in the right direction. If you are not satisfied with
                  your order, please contact the store you ordered from within
                  thirty (30) days of receipt. GreenChoice has no obligation to
                  provide refunds or credits, but may grant them at our
                  discretion. Credits are nontransferable and not exchangeable for
                  cash. If any item you request becomes unavailable on the
                  GreenChoice Services, instead of canceling an order, we may, in
                  our discretion, purchase and/or have fulfilled the unavailable
                  item(s) on your behalf from third parties. If we do this, we
                  will of course only charge you the price quoted in your
                  GreenChoice purchase order.
                </p>
  
                <p>
                  e. Taxes. Items sold on the GreenChoice Services may be subject
                  to sales tax. Prior to placing your order, you will see an
                  estimate of the tax to be collected on your order. This tax
                  amount will depend on various factors, including the items
                  purchased, the shipment destination, and the store(s) you order
                  from.
                </p>
              </li>
  
              <li>
                <h2>Additional Policies</h2>
                <p>
                  Over time, we may in our discretion implement additional
                  policies, such as regarding refunds, credits, shipping,
                  delivery, or payment. We will post these policies on the
                  GreenChoice Services; you agree that you will review these
                  policies to the extent they may pertain to or be material to
                  you.
                </p>
              </li>
  
              <li>
                <h2>No Resale</h2>
                <p>
                  You are not permitted to purchase products on GreenChoice for
                  the purpose of resale, rental, or to ship to your own customers
                  or potential customers.
                </p>
              </li>
  
              <li>
                <h2>
                  Product Information and Ratings on the GreenChoice Services
                </h2>
                <p>
                  a. Use at Your Own Risk. By using GreenChoice Services, you
                  acknowledge that any reliance upon any advice, opinion,
                  statement or other information displayed or distributed through
                  the GreenChoice Services is at your sole risk.
                </p>
  
                <p>
                  b. Accuracy, Completeness and Timeliness of Information.
                  GreenChoice works hard to ensure the accuracy of the information
                  it provides throughout GreenChoice Services. However, we rely
                  solely on data obtained from many sources, therefore we cannot
                  guarantee the accuracy of the information or any analysis that
                  uses such information. Some of the information is information we
                  have created, and some is information that has been created by
                  other brands and companies offering or creating the products.
                  Despite our efforts to be accurate, we don’t guarantee any
                  aspect of any information on the GreenChoice Services,
                  including, without limitation, search filters, product
                  categories and tags, GreenScore® ratings, product images,
                  descriptions, and specifications. If you purchase a product that
                  is not as described, you agree that your sole remedy is to
                  follow the return and refund policy of the store you ordered
                  from on GreenChoice. We reserve the right to correct any errors,
                  omissions, or publish updates on any portion of our Content
                  without notice. If you find an error or notice something that
                  doesn’t look right on the GreenChoice Services, we would greatly
                  appreciate it if you would let us know by contacting us at
                  community@greenchoicenow.com. We take information accuracy very
                  seriously, appreciate your assistance, and apologize for any
                  inconvenience.
                </p>
  
                <p>
                  c. Nutritional Information & Allergens. Any nutritional,
                  ingredient and allergen information displayed on the GreenChoice
                  Services is based on information provided by product
                  manufacturers and suppliers who may modify their products and
                  update their labels from time to time. For this reason, we
                  cannot guarantee that such information is accurate or complete.
                  In addition, any food that you purchase on GreenChoice Services
                  may be prepared, stored or packaged in facilities that process
                  the 8 major U.S. allergens – milk, eggs, fish, shellfish, tree
                  nuts, peanuts, wheat and soy. We cannot guarantee that
                  cross-contamination may occur between ingredients.
                </p>
  
                <p>
                  d. Product Information. GreenChoice does not assume any
                  liability for inaccuracies about products, whether based on
                  manufacturer information, third party information, GreenChoice
                  users’ information, or on ratings and assessments generated by
                  us. You should carefully read all information provided by the
                  manufacturers of the products, whether online or on the actual
                  product packaging and labels, including the ingredients, health
                  claims, and allergen information before using a product. For
                  additional information about a product, please contact the
                  manufacturer directly. We also encourage you to conduct the
                  appropriate research and obtain the necessary medical advice to
                  determine the best cosmetic or personal care product for your
                  needs.
                </p>
  
                <p>
                  e. Assessments, Ratings, and Opinions. We’re constantly evolving
                  our GreenScore® ratings as new research emerges and trustworthy
                  data becomes available. The descriptions used to describe each
                  rating tier constitute the opinion of GreenChoice. They only
                  qualify the food ratings according to the GreenScore® rating
                  method. These descriptions do not refer directly to the
                  product’s quality. The information provided is for informational
                  purposes only. Although we aim to provide accurate and
                  up-to-date information, no guarantee is made to that effect.
                </p>
  
                <p>
                  f. Third Party Sponsors and Advertising. There is no sponsored
                  content or advertising on the GreenChoice Services.
                </p>
  
                <p>
                  g. Affiliate Disclaimer. GreenChoice is a participant in the
                  Amazon Associates Program, an affiliate advertising program
                  designed to provide a means for us to earn fees by linking to
                  Amazon and affiliated sites. We also earn affiliate fees from
                  the stores listed on GreenChoice Services. The stores generally
                  pay us 2-4% of the cart total. What we earn from these affiliate
                  relationships enables us to provide trustworthy, unbiased
                  information to you; we also invest a portion of these fees into
                  carbon offsets that make your online grocery shopping experience
                  carbon-neutral.
                </p>
  
                <p>
                  h. Professional Healthcare Advice Disclaimer. We may offer
                  health-related products such as foods or drinks with potential
                  or actual beneficial qualities or fitness claims, or other
                  alternative health remedies.
                </p>
  
                <p>
                  Please note that no information on the GreenChoice Services, is
                  intended to be a substitute for the diagnosis, treatment or
                  advice of a medical professional. You should not use the
                  Services for the diagnosis or treatment of a health problem or
                  disease. The GreenChoice Services do not include all information
                  regarding precautions, side effects, or interactions and should
                  not be understood to indicate that any product is safe for you.{" "}
                </p>
  
                <p>
                  You should always consult your healthcare provider for more
                  information about what products you should and should not use or
                  consume. Please contact your medical professional immediately if
                  you suspect you have a medical problem. Likewise, you may
                  consult the actual product information included with the product
                  (including package inserts) or contact the manufacturer of the
                  product for additional information.
                </p>
              </li>
  
              <li>
                <h2>Privacy</h2>
                <p>
                  You consent to the collection, use, disclosure and other
                  handling of information as described in our Privacy Policy as it
                  may be updated from time to time. You consent to our monitoring
                  and recording of telephone calls, emails, and texts (and other
                  forms of communications) between you and us, no matter who
                  initiates the communication.
                </p>
              </li>
  
              <li>
                <h2>Use of Materials on the GreenChoice Services</h2>
                <p>
                  All content on the GreenChoice Services (including, for example,
                  text, design, graphics, logos, icons, images, audio clips,
                  downloads, interfaces, information, code and software, and the
                  selection and manner of compilation and presentation), is the
                  exclusive property of and owned by GreenChoice, other content
                  providers (such as GreenChoice’s store partners or suppliers) or
                  their licensors, and may be protected by copyright, trademark
                  and other applicable laws.
                </p>
  
                <p>
                  Your access to and use of the GreenChoice Services does not
                  grant you any license or right to use any trademark, logo or
                  service mark displayed on the GreenChoice Services. GreenChoice,
                  its suppliers, other content providers, or their licensors
                  retain full and complete title to and reserve all rights in the
                  material on the GreenChoice Services, including all associated
                  intellectual property rights. GreenChoice neither warrants nor
                  represents that your use of materials on the GreenChoice
                  Services will not infringe rights of third parties.
                </p>
  
                <p>
                  You may access the GreenChoice Services only for your personal
                  and non-commercial use, and you may not modify or delete any
                  copyright, trademark or other proprietary notice that appears on
                  the material you access.
                </p>
  
                <p>
                  Any other use of the material on the GreenChoice Services,
                  including, without limitation, the modification, distribution,
                  transmission, performance, publication, uploading, licensing,
                  reverse engineering, transfer or sale of, or the creation of
                  derivative works from, any material, information, software,
                  products or services obtained from the GreenChoice Services, or
                  use of the GreenChoice Services for purposes competitive with
                  GreenChoice, or for other commercial purposes, is expressly
                  prohibited. You agree to abide by all additional restrictions
                  displayed on the GreenChoice Services as they may be updated
                  from time to time.
                </p>
              </li>
  
              <li>
                <h2>Materials You Submit</h2>
                <p>
                  You acknowledge that you are responsible for all materials you
                  submit to GreenChoice via the GreenChoice Services or other
                  electronic communications (including through any components of
                  the GreenChoice Services administered by third parties, such as
                  tools that allow you to interact with the GreenChoice Services
                  through Facebook and other social media platforms), including
                  the legality, accuracy, appropriateness, originality and
                  copyright of any such material.
                </p>
  
                <p>
                  Unless we indicate otherwise, if you submit any material to us,
                  you grant GreenChoice an unrestricted, worldwide, nonexclusive,
                  royalty-free, perpetual, irrevocable and fully sublicensable
                  right to use, reproduce, modify, adapt, publish, translate,
                  create derivative works from, distribute and display these
                  material throughout the world in any media. You further agree
                  that GreenChoice is free to use any ideas, concepts, feedback
                  and know-how that you provide to GreenChoice. You grant
                  GreenChoice the right to use the name you submit in connection
                  with such material, if we choose. You represent and warrant that
                  you own or otherwise control in perpetuity all the rights to the
                  content you submit, that such content is accurate, and that use
                  of such content will not violate any law or these Terms of Use.
                </p>
  
                <p>
                  You agree not to submit content that (i) infringes,
                  misappropriates or violates a third party’s patent, copyright,
                  trademark, trade secret, , rights of publicity or privacy or
                  other intellectual property rights; (ii) violates, or encourages
                  any conduct that would violate, any applicable law or regulation
                  or would give rise to civil liability; (iii) is fraudulent,
                  false, misleading or deceptive; (iv) is defamatory, dishonest,
                  obscene, pornographic, vulgar or offensive; (v) promotes
                  discrimination, bigotry, racism, hatred, harassment or harm
                  against any individual or group; (vi) is violent or threatening
                  or promotes violence or actions that are threatening to any
                  person or entity; (vii) promotes illegal or harmful activities
                  or substances or is illegal or harmful; (viii) has a commercial
                  purpose; or (ix) can be considered a third party’s private or
                  confidential information.
                </p>
              </li>
  
              <li>
                <h2>Restrictions on Access and Use</h2>
                <p>
                  When accessing and using the GreenChoice Services, you agree
                  that you will not:
                </p>
                <p>
                  – Violate any law, rule or regulation, or these Terms of Use;
                </p>
                <p>
                  – Engage in conduct that is fraudulent or otherwise harmful to
                  GreenChoice, our partners or any other user;
                </p>
                <p>
                  – Display to others, mirror or frame the GreenChoice Services,
                  or any component of the GreenChoice Services;
                </p>
                <p>
                  – Access, tamper with, or use non-public areas of the
                  GreenChoice Services, GreenChoice’s computer systems, or the
                  technical delivery systems of GreenChoice or GreenChoice’s
                  providers;
                </p>
                <p>
                  – Probe, scan, or test the vulnerability of any GreenChoice
                  Services or breach any security or authentication measures;
                </p>
                <p>
                  – Interfere with the access of any user, host or network,
                  including, without limitation, by sending a virus, overloading,
                  flooding, spamming, or mail-bombing the GreenChoice Services;
                </p>
                – Avoid, bypass, remove, deactivate, impair, descramble or
                otherwise circumvent any technological measure intended to
                protect, limit access to or control the GreenChoice Services;
                <p>
                  – Disclose, or permit third parties to use, your login
                  credentials and/or account;
                </p>
                <p>
                  – Use a third party’s credentials, conceal your true IP address,
                  or otherwise impersonate or misrepresent your identity or
                  affiliation with any person or entity;
                </p>
                <p>
                  – Use the GreenChoice Services to determine whether a third
                  party holds any type of account, or to learn about or verify
                  information about the account that is not yours;
                </p>
                <p>
                  – Access the GreenChoice Services if we have prohibited you from
                  such access;
                </p>
                <p>
                  – Send any unsolicited or unauthorized advertising, promotional
                  materials, email, junk mail, spam, chain letters or other form
                  of solicitation;
                </p>
                <p>
                  – Use any meta tags or other hidden text or metadata utilizing a
                  GreenChoice trademark, logo, URL or product name without
                  GreenChoice’s express written consent;
                </p>
                <p>
                  – Decipher, decompile, disassemble or reverse engineer any of
                  the software used on or in connection with the GreenChoice
                  Services;
                </p>
                <p>
                  – Collect any personally identifiable information from users of
                  the GreenChoice Services or use any such information found on
                  the GreenChoice Services.
                </p>
                <p>
                  – Attempt to do anything, or permit, encourage or allow anything
                  party to do anything, prohibited in this list of bulleted items,
                  or attempt, permit, or allow or encourage any other violation of
                  these Terms of Use.
                </p>
                <p>
                  You agree that you will only access the GreenChoice Services
                  from devices that have up-to-date operating systems and security
                  software.
                </p>
                <p>
                  You agree not to, directly or indirectly (e.g., through a third
                  party), attempt to access, search, or otherwise use the
                  GreenChoice Services (such as by attempting to retrieve
                  information from or about the GreenChoice Services) through the
                  use of any engine, software, tool, agent, device or mechanism
                  (including, without limitation, spiders, robots, crawlers and
                  data mining tools) other than generally available third-party
                  web browsers that (1) provide accurate and complete User-Agent
                  information in the HTTP header, such as Safari, Firefox, Chrome
                  and Internet Explorer and (2) have not been modified with
                  plugins or otherwise in a manner that facilitates automated or
                  faster-than-normal access to, searching of, or other use of the
                  GreenChoice Services. You also agree not to disregard our
                  robots.txt files (but in the event of a conflict between these
                  Terms of Use and a GreenChoice Site’s robots.txt file, the more
                  restrictive of the two shall apply).
                </p>
                <p>
                  If you attempt to access, search or otherwise use the
                  GreenChoice Services in any way other than through a generally
                  available third-party web browser meeting the conditions
                  described above, or if you disregard our robots.txt files, you
                  agree that such action shall constitute your permanent consent
                  and authorization for GreenChoice to immediately and without
                  notice to you, access, search, retrieve information from or
                  about, or otherwise use, any websites that you or your
                  affiliates own, operate or control, through any engine,
                  software, tool, agent, device or mechanism that GreenChoice
                  considers appropriate, including, without limitation, spiders,
                  robots, crawlers and data mining tools, regardless of any
                  statement on such websites (or in their terms of use, terms of
                  service, robots.txt files, or the like) to the contrary, either
                  directly or indirectly (e.g., through a third party).
                </p>
                <p>
                  If you are accessing the GreenChoice Services as an employee or
                  agent, you acknowledge that you are extending the permanent
                  consent and authorization described above on behalf of your
                  employer or principal, and you represent that you have authority
                  from that employer or principal to do so.
                </p>
                <p>
                  If you are accessing the GreenChoice Services as an employer or
                  principal, you authorize your employee or agent to extend on
                  your behalf the permanent consent and authorization described
                  above, and you acknowledge that your permanent consent and
                  authorization will bind all of your employees and agents.
                </p>
              </li>
  
              <li>
                <h2>Notice of Copyright Infringement</h2>
                <p>
                  GreenChoice has reserved the right, in its sole discretion, to
                  terminate users of the GreenChoice Services, including
                  GreenChoice’s store partners, who infringe on copyrights of
                  GreenChoice or others. If you believe that your copyright, or
                  the copyright of a person on whose behalf you are authorized to
                  act, has been infringed, please send a written notice to
                  GreenChoice at community@greenchoicenow.com containing the
                  following information:
                </p>
  
                <p>
                  – A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed.
                </p>
  
                <p>
                  – Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works are covered by a
                  single notification, a representative list of such works.
                </p>
  
                <p>
                  – Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and that
                  is to be removed or access to which is to be disabled, and
                  information reasonably sufficient to permit us to locate the
                  material.
                </p>
  
                <p>
                  – Contact information for the notifying party, including name,
                  address, telephone number, and email address.
                </p>
  
                <p>
                  – A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright owner, its agent, or the law.
                </p>
  
                <p>
                  – A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are authorized
                  to act on behalf of the owner of an exclusive right that is
                  allegedly infringed.
                </p>
              </li>
  
              <li>
                <h2>DISCLAIMER OF WARRANTIES</h2>
                <p>
                  This section of these Terms of Use is important because
                  GreenChoice, as an ecommerce platform, disclaims certain
                  warranties and liabilities. In other words, this agreement means
                  we will not assume responsibility for certain things that may
                  occur. We nonetheless do intend to try to help our customers
                  wherever we can (without legal obligations).
                </p>
                <p>
                  THIS DISCLAIMER SECTION IS AN ESSENTIAL PART OF THESE TERMS OF
                  USE. THIS DISCLAIMER DOES NOT APPLY TO ANY PRODUCT WARRANTY MADE
                  TO YOU BY THE MANUFACTURER OF THE ITEM.
                </p>
                <p>
                  YOUR USE OF THE GreenChoice Services IS AT YOUR SOLE RISK. THE
                  GreenChoice Services, AND ALL INFORMATION, CONTENT, MATERIALS,
                  PRODUCTS, ADVICE AND OTHER SERVICES INCLUDED ON OR OTHERWISE
                  MADE AVAILABLE TO YOU THROUGH THE GreenChoice Services ARE
                  PROVIDED SOLELY ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                </p>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THESE
                  DISCLAIMERS INCLUDE, WITHOUT LIMITATION:
                </p>
                <p>
                  – THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE;
                </p>
                <p>
                  – ANY WARRANTIES IMPLIED FROM A COURSE OF PERFORMANCE OR COURSE
                  OF DEALING;
                </p>
                <p>
                  – THAT ACCESS TO THE GREENCHOICE SERVICES WILL BE UNINTERRUPTED
                  OR ERROR-FREE;
                </p>
                <p>– THAT THE GREENCHOICE SERVICES WILL BE SECURE;</p>
                <p>
                  – THAT THE GREENCHOICE SERVICES OR THE SERVERS THAT MAKE THE
                  SITES AVAILABLE WILL BE VIRUS-FREE; AND
                </p>
                <p>
                  – THAT COMMUNICATIONS SENT FROM THE GREENCHOICE SERVICES ARE
                  FREE OF MALWARE OR OTHER HARMFUL COMPONENTS.
                </p>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY
                  FOR YOUR USE OF THE SITES AND THAT ANY INFORMATION YOU SEND OR
                  RECEIVE DURING YOUR USE OF THE SITES MAY NOT BE SECURE AND MAY
                  BE INTERCEPTED OR OTHERWISE ACCESSED BY UNAUTHORIZED PARTIES.
                </p>
                <p>
                  CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                  THESE LAWS APPLY TO YOU DESPITE THE “APPLICABLE LAW” SECTION OF
                  THESE TERMS OF USE, SOME OR ALL OF THE ABOVE MAY NOT APPLY TO
                  YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </p>
              </li>
  
              <li>
                <h2>LIMITATION OF LIABILITY</h2>
                <p>
                  This section of these Terms of Use is also important because
                  GreenChoice limits many of its liabilities. We limit our
                  liabilities for many reasons, but mainly because it helps us to
                  provide our platform to you as efficiently as possible, and (we
                  believe) correct any dissatisfaction without means of lawsuits
                  or extensive legal disputes.
                </p>
  
                <p>
                  IN NO EVENT SHALL GREENCHOICE OR ITS OFFICERS, DIRECTORS,
                  SHAREHOLDERS, INVESTORS, AGENTS, EMPLOYEES, CONTRACTORS,
                  REPRESENTATIVES, AFFILIATES, PARENTS, SUBSIDIARIES, LICENSORS,
                  SUBLICENSEES, SUCCESSORS OR ASSIGNS (COLLECTIVELY, WITH
                  GREENCHOICE, THE “GREENCHOICE ENTITIES”) BE LIABLE TO YOU OR ANY
                  THIRD PARTY FOR ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF DATA,
                  INTERRUPTION OF BUSINESS, OR ANY INDIRECT, INCIDENTAL, SPECIAL
                  OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED
                  WITH THE USE OF THE SERVICE OR WITH THE DELAY OR INABILITY TO
                  USE SAME, OR FOR ANY BREACH OF SECURITY, OR FOR ANY CONTENT,
                  MENU ITEMS, AND SERVICE OBTAINED THROUGH OR VIEWED ON THE
                  SERVICE, OR OTHERWISE ARISING OUT OF THE USE OF SAME, WHETHER
                  BASED ON CONTRACT, TORT, STRICT LIABILITY, REGULATION, COMMON
                  LAW PRECEDENT OR OTHERWISE, EVEN IF THE GreenChoice ENTITIES
                  HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES AND EVEN IF SUCH
                  DAMAGES RESULT FROM ANY GreenChoice ENTITY’S NEGLIGENCE OR GROSS
                  NEGLIGENCE. IN NO EVENT SHALL THE GreenChoice ENTITIES’
                  AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICE
                  EXCEED ONE HUNDRED U.S. DOLLARS (U.S. $200.00) OR THE COST OF
                  THE INCIDENT (E.G., YOUR PURCHASED GOODS)) FROM WHICH ANY
                  ALLEGED CLAIM ARISES. ADDITIONAL DISCLAIMERS BY GreenChoice MAY
                  APPEAR WITHIN THE SERVICE AND ARE INCORPORATED HEREIN BY
                  REFERENCE. TO THE EXTENT ANY SUCH DISCLAIMERS PLACE GREATER
                  RESTRICTIONS ON YOUR USE OF THE SERVICE OR THE MATERIAL
                  CONTAINED THEREIN, SUCH GREATER RESTRICTIONS SHALL APPLY. SOME
                  JURISDICTIONS RESTRICT OR DO NOT ALLOW THE LIMITATION OF
                  LIABILITY IN CONTRACTS, AND AS A RESULT THE CONTENTS OF THIS
                  SECTION MAY NOT APPLY TO YOU. IN CASES WHERE SUCH LAWS APPLY,
                  LIABILITY OF THE GreenChoice ENTITIES SHALL BE LIMITED TO THE
                  FULLEST EXTENT PERMITTED BY LAW AND SHALL NOT HAVE THE EFFECT OR
                  WAIVING RIGHTS THAT MAY NOT BE WAIVED AS A MATTER OF LAW.
                </p>
  
                <p>
                  CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                  THESE LAWS APPLY TO YOU DESPITE THE “APPLICABLE LAW” SECTION OF
                  THESE TERMS OF USE, SOME OR ALL OF THE ABOVE MAY NOT APPLY TO
                  YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </p>
              </li>
  
              <li>
                <h2>Indemnification</h2>
                <p>
                  You agree to indemnify, defend and hold harmless GreenChoice and
                  its subsidiaries and affiliates, and as to each, their officers,
                  directors, employees, contractors, agents, licensors, service
                  providers, subcontractors and suppliers, from and against any
                  and all losses, liabilities, expenses, damages and costs,
                  including reasonable attorneys’ fees and court costs, arising
                  out of or relating to your access to or use of the GreenChoice
                  Services, any content you post or submit to the GreenChoice
                  Services, your violation of law or infringement of the rights of
                  a third party, or your other violation of these Terms of Use.
                  GreenChoice reserves the right, at its own expense, to assume
                  exclusive defense and control of any matter otherwise subject to
                  indemnification by you and, in any case, you agree to cooperate
                  with GreenChoice if and as requested by GreenChoice in the
                  defense of such matter.
                </p>
              </li>
  
              <li>
                <h2>Massachusetts Law Applies</h2>
                <p>
                  The laws of the State of Massachusetts apply to everything
                  relating GreenChoice and the GreenChoice Services relationship
                  to you. This means that any and all disputes, claims and
                  controversies arising out of or relating to the GreenChoice
                  Services, including the sale of products or the content,
                  services, or technology, on or used through the GreenChoice
                  Services, shall be governed by and construed exclusively in
                  accordance with the laws and decisions of the State of
                  Massachusetts.
                </p>
              </li>
  
              <li>
                <h2>Arbitration and Class Action Waiver</h2>
                <p>
                  PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL
                  RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
                </p>
  
                <p>
                  You and GreenChoice agree that these Terms may affect interstate
                  commerce and that to the broadest extent permissible under law,
                  the Federal Arbitration Act governs the interpretation and
                  enforcement of these arbitration provisions.
                </p>
  
                <p>
                  This Section is intended to be interpreted broadly and governs
                  any and all disputes between us, including but not limited to
                  claims arising out of or relating to any aspect of the
                  relationship between us, whether based in contract, tort,
                  statute, fraud, misrepresentation or any other legal theory;
                  claims that arose before these Terms or any prior agreement
                  (including, but not limited to, claims related to advertising,
                  and any content available on or through any GreenChoice
                  Services); and claims that may arise after the termination of
                  these Terms. The only disputes excluded from this broad
                  prohibition are the litigation of certain intellectual property
                  and small court claims, as provided below.
                </p>
  
                <p>
                  By agreeing to these Terms, you agree to resolve any and all
                  disputes with GreenChoice as follows:
                </p>
  
                <p>
                  Initial Dispute Resolution: Most disputes can be resolved
                  without resort to litigation. You can reach GreenChoice at
                  community@greenchoicenow.com. Except for intellectual property
                  and small claims court claims, the parties agree to use their
                  best efforts to settle any dispute, claim, question, or
                  disagreement directly through consultation with GreenChoice, and
                  good faith negotiations shall be a condition to either party
                  initiating a lawsuit or arbitration.
                </p>
  
                <p>
                  Binding Arbitration: If the parties do not reach an agreed-upon
                  solution within a period of thirty (30) days from the time
                  informal dispute resolution is initiated under the Initial
                  Dispute Resolution provision above, then either party may
                  initiate binding arbitration as the sole means to resolve
                  claims, subject to the terms set forth below. Specifically, all
                  claims arising out of or relating to these Terms (including the
                  Terms’ or Privacy Policy’s formation, performance, and breach),
                  the parties’ relationship with each other, and/or your use of
                  our App or our Site shall be finally settled by binding
                  arbitration administered by JAMS in accordance with the JAMS
                  Streamlined Arbitration Procedure Rules for claims that do not
                  exceed $250,000 and the JAMS Comprehensive Arbitration Rules and
                  Procedures for claims exceeding $250,000 in effect at the time
                  the arbitration is initiated, excluding any rules or procedures
                  governing or permitting class actions. The arbitrator, and not
                  any federal, state, or local court or agency, shall have
                  exclusive authority to resolve all disputes arising out of or
                  relating to the interpretation, applicability, enforceability,
                  or formation of these Terms or the Privacy Policy, including but
                  not limited to any claim that all or any part of these Terms or
                  Privacy Policy is void or voidable, whether a claim is subject
                  to arbitration, or the question of waiver by litigation conduct.
                  The arbitrator shall be empowered to grant whatever relief would
                  be available in a court under law or in equity. The arbitrator’s
                  award shall be written and shall be binding on the parties and
                  may be entered as a judgment in any court of competent
                  jurisdiction.
                </p>
  
                <p>
                  To the extent the filing fee for the arbitration exceeds the
                  cost of filing a lawsuit, GreenChoice will pay the additional
                  cost. If the arbitrator finds the arbitration to be
                  non-frivolous, GreenChoice will pay the fees invoiced by JAMS,
                  including filing fees and arbitrator and hearing expenses. You
                  are responsible for your own attorneys’ fees unless the
                  arbitration rules and/or applicable law provide otherwise.
                </p>
                <p>
                  The parties understand that, absent this mandatory arbitration
                  provision, they would have the right to sue in court and have a
                  jury trial. They further understand that, in some instances, the
                  costs of arbitration could exceed the costs of litigation and
                  the right to discovery may be more limited in arbitration than
                  in court.
                </p>
  
                <p>
                  You and GreenChoice agree that any arbitration will take place
                  in the county of your billing address, provided that you may
                  attend telephonically or videographically, in which case we will
                  do the same. You and GreenChoice further agree to submit to the
                  personal jurisdiction of any federal or state court in Suffolk
                  County, Massachusetts in order to compel arbitration, to stay
                  proceedings pending arbitration, or to confirm, modify, vacate,
                  or enter judgment on the award entered by the arbitrator.
                </p>
  
                <p>
                  Class Action Waiver: The parties further agree that the
                  arbitration shall be conducted in their individual capacities
                  only and not as a class action or other representative action,
                  and the parties expressly waive their right to file a class
                  action or seek relief on a class basis. YOU AND GREENCHOICE
                  AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR
                  OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                  MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If
                  any court or arbitrator determines that the class action waiver
                  set forth in this paragraph is void or unenforceable for any
                  reason or that an arbitration can proceed on a class basis, then
                  the arbitration provisions set forth above (but not the
                  remainder of the Terms of Use) shall be deemed null and void in
                  their entirety and the parties shall be deemed to have not
                  agreed to arbitrate disputes.
                </p>
  
                <p>
                  Exception for Litigation of Intellectual Property and Small
                  Claims Court Claims: Notwithstanding the parties’ decision to
                  resolve all disputes through arbitration, either party may bring
                  enforcement actions, validity determinations or claims arising
                  from or relating to theft, piracy or unauthorized use of
                  intellectual property in state or federal court or in the U.S.
                  Patent and Trademark Office to protect its intellectual property
                  rights (“intellectual property rights” means patents,
                  copyrights, trademarks, and trade secrets, but not privacy or
                  publicity rights). Either party may also seek relief in a small
                  claims court for disputes or claims within the scope of that
                  court’s jurisdiction.
                </p>
  
                <p>
                  30-Day Right to Opt Out: You have the right to opt out and not
                  be bound by the arbitration and class action waiver provisions
                  set forth above by sending (from the email address you used to
                  create your Account) written notice of your decision to opt out
                  to community@greenchoicenow.com with the subject line,
                  “ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.” The notice must
                  be sent within thirty (30) days of your agreement to these
                  Terms; otherwise, you shall be bound to arbitrate disputes in
                  accordance with the terms of those paragraphs. If you opt out of
                  these arbitration provisions, GreenChoice also will not be bound
                  by them.
                </p>
  
                <p>
                  Changes to This Section: GreenChoice will provide thirty (30)
                  days’ notice of any changes to this section by posting on the
                  Service, sending you a message, or otherwise notifying you when
                  you are logged into your Account. Amendments will become
                  effective thirty (30) days after they are posted on the Service
                  or sent to you.
                </p>
  
                <p>
                  Changes to this section will otherwise apply prospectively only
                  to claims arising after the thirtieth (30th) day. If a court or
                  arbitrator decides that this subsection on “Changes to This
                  Section” is not enforceable or valid, then this subsection shall
                  be severed from the section entitled “Arbitration and Class
                  Action Waiver,” and the court or arbitrator shall apply the
                  first Arbitration and Class Action Waiver section in existence
                  after you began using the Service.
                </p>
  
                <p>
                  Survival: This Arbitration and Class Action Waiver section shall
                  survive any termination of your Account or the Service.
                </p>
              </li>
  
              <li>
                <h2>Changes to These Terms of Use</h2>
                <p>
                  GreenChoice may amend these Terms of Use (including any Service
                  Terms) at any time and at GreenChoice’s sole discretion by
                  posting the updated version on www.greenchoicenow.com. Unless
                  you reject the update as described below, you will be deemed to
                  accept the changes and they will take effect on the new
                  “effective” date posted. If you do not accept such an amendment,
                  you may close your account and we will refund any stored value
                  or credits (if any) within thirty (30) days, provided you have
                  given us your correct and updated information permitting such
                  refund.
                </p>
  
                <p>
                  However, the foregoing does not apply to section 13; a change to
                  section 13 may only occur upon the bilateral agreement of both
                  of us.
                </p>
              </li>
  
              <li>
                <h2>Termination by GreenChoice</h2>
                <p>
                  We may close your account at any time in our sole discretion and
                  without notice to you. In no case will GreenChoice’s closure of
                  your account, or reduction of your access to the GreenChoice
                  Services, waive or affect any other right or relief to which
                  GreenChoice may be entitled.
                </p>
              </li>
  
              <li>
                <h2>
                  Entire Agreement and Admissibility; Applicability to Sellers
                </h2>
                <p>
                  These Terms of Use constitute the entire agreement and
                  understanding between you and GreenChoice with respect to the
                  subject matter hereof and supersede all prior or contemporaneous
                  communications and proposals, whether oral or written, between
                  the parties with respect to such subject matter. A printed
                  version of these Terms of Use shall be admissible in judicial,
                  arbitral or administrative proceedings to the same extent and
                  subject to the same conditions as other business documents and
                  records originally generated and maintained in printed form.
                </p>
              </li>
  
              <li>
                <h2>Miscellaneous</h2>
                <p>
                  You may not assign or otherwise transfer these Terms of Use, by
                  operation of law or otherwise, without GreenChoice’s prior
                  written consent. Subject to the foregoing restriction, these
                  Terms of Use will be binding on, inure to, and be enforceable
                  against the parties and their respective successors and assigns.
                  GreenChoice may assign these Terms of Use in its sole discretion
                  by providing you notice of the same. For example, GreenChoice
                  may unconditionally assign or otherwise transfer these Terms of
                  Use to any successor in interest, such as an acquirer of a
                  GreenChoice company (via merger, sale of stock, etc.) or a buyer
                  of substantially all of its assets.
                </p>
  
                <p>
                  Headings are for reference purposes only and do not limit the
                  scope or extent of any section.
                </p>
  
                <p>
                  GreenChoice’s failure to enforce your strict performance of any
                  provision of these Terms of Use will not constitute a waiver of
                  GreenChoice’s right to enforce such provision or any other
                  provision of these Terms of Use.
                </p>
              </li>
  
              <li>
                <h2>How to Contact Us</h2>
                <p>
                  If you have any questions or comments, please contact our team
                  by:
                </p>
  
                <p>(1) emailing us at community@greenchoicenow.com</p>
  
                <p>(2) contacting us by phone at 1-617-855-9025</p>
  
                <p>
                  (3) mailing us at GreenChoice, PBC at the following address:{" "}
                </p>
  
                <p>GreenChoice <br/>
                  444 Somerville Ave. <br/>
                  Somerville, MA. 02143</p>
  
                <p>
                  <strong>
                      Much love & health, 
                     <br/> 
                     Team GreenChoice
                  </strong>
                </p>
              </li>
            </ol>
          </section>
        </div>
      </main>
   </>
  );
};

export default Terms;
