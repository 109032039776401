import { createBrowserRouter } from "react-router-dom";
import Layout from "../Pages/Layout";
import ProductDetailPage from "../Pages/ProductDetailPage";
import Glossary from "../Pages/Glossary";
import Greenscore from "../Pages/Greenscore";
import Terms from "../Pages/Terms";
import NotFound from "../Pages/NotFound";
import Filters from "../Pages/Filters";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/p/:name",
        element: <ProductDetailPage />,
      },
      {
        path: "/glossary",
        element: <Glossary />,
      },
      {
        path: "/greenscore",
        element: <Greenscore />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/preferences-setup",
        element: <Filters />
      }
    ],
  },
  {
    path: "*",
    element: <NotFound />
  }
]);
