export const handleFaqClick = (question) => {
  window.gtag("event", "faq_click", {
    event_label: "FAQ Question",
    event_value: question,
  });
};

export const handleRadialClick = (productName) => {
  window.gtag("event", "product_page_GS_radial_click", {
    event_label: "Product Page GS Radial",
    event_value: productName,
  });
};

export const handleProductCardClick = (carousel, productName) => {
  window.gtag("event", "better_alternatives_product_click", {
    event_label: "Product Cards",
    event_value: `${carousel} ${productName}`,
  });
};

export const handleSlideTrack = (productName) => {
  window.gtag("event", "product_page_image_slide", {
    event_label: "PDP Banner Image Slider",
    event_value: productName,
  });
};

export const handleTabsClicked = (tabname) => {
  window.gtag("event", "product_page_tabs_clicked", {
    event_label: "Product Page Tabs Clicked",
    event_value: tabname,
  });
};

export const handleDisclaimerClicked = () => {
  window.gtag("event", "disclaimer_cta_clicked", {
    event_label: "Disclaimer",
    event_value: "Disclaimer CTA click",
  });
};

export const handleReportClicked = (product) => {
  window.gtag("event", "report_cta_clicked", {
    event_label: "Bug Report",
    event_value: `Report CTA click for ${product}`,
  });
};

export const handleBetterAlternatives = () => {
  window.gtag("event", "better_alternatives_slide", {
    event_label: "Better-for-you alternatives",
    event_value: "Better alternative carousel",
  });
};

export const handleSimilarProducts = () => {
  window.gtag("event", "product_pairings_slide", {
    event_label: "Product pairings",
    event_value: "Product pairings carousel",
  });
};

export const handleProcessingGSClicked = () => {
  window.gtag("event", "gs_chart_processing_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: "Processing GS click",
  });
};

export const handleNutritionGSClicked = () => {
  window.gtag("event", "gs_chart_nutrition_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: "Nutrition GS click",
  });
};

export const handleFoodSafetyGSClicked = () => {
  window.gtag("event", "gs_chart_food_safety_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: "Food safety GS click",
  });
};

export const handleEnvironmentGSClicked = () => {
  window.gtag("event", "gs_chart_environment_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: "Environment GS click",
  });
};

export const handleDailyValueModal = () => {
  window.gtag("event", "gs_chart_daily_value_popup", {
    event_label: "Product Page GS Chart",
    event_value: "GS daily value modal clicked",
  });
}

export const handleFoodSafetyModal = () => {
  window.gtag("event", "gs_chart_food_safety_popup", {
    event_label: "Product Page GS Chart",
    event_value: "GS food safety modal clicked",
  });
}

export const handleProcessingModal = () => {
  window.gtag("event", "gs_chart_processing_popup", {
    event_label: "Product Page GS Chart",
    event_value: "GS processing modal clicked",
  });
}

export const handleEnvironmentModal = () => {
  window.gtag("event", "gs_chart_environment_popup", {
    event_label: "Product Page GS Chart",
    event_value: "GS environment footprint modal clicked",
  });
}

export const handleEncourageNutrition = (nutrient) => {
  window.gtag("event", "gs_chart_encourage_nutrient_clicked", {
    event_label: "Product Page Encourage Nutrition",
    event_value: `Encourage ${nutrient}`,
  });
}

export const handleLimitNutrition = (nutrient) => {
  window.gtag("event", "gs_chart_limit_nutrient_clicked", {
    event_label: "Product Page Limit Nutrition",
    event_value: `Limit ${nutrient}`,
  });
}

export const handleCreateProfile = () => {
  window.gtag("event", "create_profile_clicked", {
    event_label: "Create My Dietary Profile",
    event_value: `${JSON.parse(localStorage.getItem("userId"))}`,
  });
}

export const handleSelectedFilters = (value) => {
  window.gtag("event", "user_filters", {
    event_label: "Selected filters",
    event_value: value,
  });
}
