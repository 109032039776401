import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import ScrollToTop from '../../Components/ScrolltoTop'
import { ErrorBoundary } from 'react-error-boundary';
import NotFound from '../NotFound'
import './index.scss';

function ErrorFallback({ error, resetErrorBoundary }) {
  function handleReset() {
    window.location.reload();
  }
  return (
    <div className="error-fallback">
      <div className="container">
        <h2>Oops! Something went wrong.</h2>
        <p>We're sorry, but an unexpected error occurred:</p>
        <pre>{error.message}</pre>
        <button onClick={handleReset}>Try again</button>
      </div>
    </div>
  );
}

const Layout = () => {
  const location = useLocation();
  const isSpecificPage = location.pathname === '/preferences-setup';
  return (
    <>
      <Header />
      <ErrorBoundary FallbackComponent={NotFound}>
        <main>
          <ScrollToTop />
          <Outlet />
        </main>
      </ErrorBoundary>
      <Footer disabled={isSpecificPage} />
    </>
  )
}

export default Layout