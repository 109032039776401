import React, { useEffect, useState } from 'react'
import { useFilters } from '../../Context/FiltersContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import "./index.scss"

const PDPFilterList = ({ product }) => {
    const [unmatchedFilters, setUnmatchedFilters] = useState([]);
    const [combinedFilters, setCombinedFilters] = useState([]);
    const [showAllFilters, setShowAllFilters] = useState(false);

    const { checkConflicts, selectedFilters, chipColors, getFilterLabelById } =
        useFilters();

    useEffect(() => {
        if (product) {
            checkConflicts(product);
        }
        return () => {
            setUnmatchedFilters([]);
            setCombinedFilters([]);
        };
    }, [product])

    useEffect(() => {
        if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
            setCombinedFilters([]);
            setUnmatchedFilters([]);
            return;
        }
        const setFilters = () => {
            const unmatchedFilters = [];
            const matchedFilters = [];

            if (selectedFilters && chipColors) {
                Object.keys(selectedFilters).length > 0 &&
                    Object.keys(selectedFilters).forEach((compositeKey) => {
                        if (chipColors[compositeKey] === "#FCDDDD") {
                            unmatchedFilters.push(compositeKey);
                        } else {
                            matchedFilters.push(compositeKey);
                        }
                    });
            }
            setCombinedFilters([...unmatchedFilters, ...matchedFilters]);
            setUnmatchedFilters(unmatchedFilters);
        };
        setFilters();
    }, [selectedFilters, chipColors]);

    return (
        <>
            {product && (
                <div className="pdp-filter-list">
                    {combinedFilters && combinedFilters.length > 0 &&
                        combinedFilters
                            .slice(0, showAllFilters ? combinedFilters.length : 2)
                            .map((compositeKey, index) => {
                                const isUnmatched = unmatchedFilters.includes(compositeKey);
                                return (
                                    <div
                                        key={compositeKey}
                                        style={{ background: isUnmatched ? "#fcdddd" : "#eee" }}
                                        className={`filter-list
                            ${index === 0 ? "rounded-top-only" : ""} 
                            ${index === combinedFilters.length - 1 ? "rounded-bottom-only" : ""}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={isUnmatched ? faExclamationCircle : faCheckCircle}
                                            style={{ fontSize: 15, color: isUnmatched ? "#F15754" : "#17B169" }}
                                        />
                                        <p className='mb-0'>
                                            {getFilterLabelById(
                                                compositeKey.split("_")[0],
                                                parseInt(compositeKey.split("_")[1])
                                            )}
                                        </p>
                                    </div>
                                );
                            })}

                    {combinedFilters && combinedFilters.length > 0 && combinedFilters.length > 2 && (
                        <button
                            onClick={() => setShowAllFilters(!showAllFilters)}
                            className='more-less-show-btn'
                        >
                            <p className="text-center px-1 mb-0">
                                {showAllFilters ? "See Less" : "See More"}
                            </p>
                            {showAllFilters ? (
                                <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: 10, color: "#4e4e4e" }} />
                            ) : (
                                <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: 10, color: "#4e4e4e" }} />
                            )}
                        </button>
                    )}
                </div>
            )}
        </>
    )
}

export default PDPFilterList