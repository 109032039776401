import React, { Suspense, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { getOtherProductData, getProductData } from "../../APIs/functions";
import ProductBanner from "../../Components/ProductBanner";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";
import { capitalizeFirstChar } from "../../Utils/CommonFunctions";
import Preference from "../../Components/PreferenceSetup";
import ProductInfoTabs from "../../Components/ProductInfoTabs";
import Alternative from "../../Components/AlternativesCarousel";
import ProductPairing from "../../Components/ProductPairings";
// import { ErrorBoundary } from "react-error-boundary";
// import NotFound from "../NotFound";
import MoreOptions from "../../Components/MoreOptions"
import { storesArr } from "../../Utils/Constants"
import { useFilters } from "../../Context/FiltersContext";
import "./index.scss";

const ProductDetailPage = () => {

  //handle disabling bfc but keep few pages same
  const clientUrl = new URL(window.location.href);
  const hostnameParts = clientUrl.hostname.split('.');
  const store = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  const storeObject = storesArr ? storesArr.find((item) => item.name === store) : {};
  if (storeObject?.name === "bfc") {
    const validPaths = [
      "/p/barbaras-bakery-puffins-cereal-peanut-butter",
      "/p/a-dozen-cousins-ready-to-eat-beans-cuban-black-beans-pouch",
      "/p/abilyns-bakery-ice-cream-cake-vanilla-chocolate",
      "/p/barilla-classic-oven-ready-lasagne-pasta",
      "/p/Barbaras-Bakery-Shredded-Wheat-Cereal-Case-of",
    ];
    if (!validPaths.includes(clientUrl.pathname)) {
      window.location.replace("https://about.greenchoicenow.com/mobile-app");
      return null;
    }
  }

  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    retry: false,
    cacheTime: 525000,
    staleTime: 500000,
  };

  const params = useParams();
  const url = useLocation();

  const scrollToChartRef = useRef(null);

  const productName = params?.name;

  const { loadFilters } = useFilters()

  document.title = capitalizeFirstChar(productName?.split("-").join(" "));

  const {
    data: productData,
    isLoading: productDataLoading,
    isError: productDataError,
  } = useQuery(
    ["productData", productName],
    () => getProductData({ productName }),
    queries
  );

  const product = !productDataLoading && !productDataError && productData?.data ? productData?.data : productData

  const {
    data: additionalData,
    isLoading: productOtherDataLoading,
    isError: productOtherDataError,
  } = useQuery(
    ["productOtherData", productName],
    () => getOtherProductData({ productName }),
    queries
  );

  if (!productDataLoading && !productOtherDataLoading) {
    var obj = {
      dv: {
        totalCarbs: additionalData?.nutritionMasterData?.Total_Carbohydrate_DV,
        totalFat: additionalData?.nutritionMasterData?.Total_Fat_DV,
        saturatedFat: additionalData?.nutritionMasterData?.Saturated_Fat_DV,
        transFat: "N/A",
        cholestrol: additionalData?.nutritionMasterData?.Cholesterol_DV,
        sodium: additionalData?.nutritionMasterData?.Sodium_DV,
        sugars: "N/A",
        dietaryFiber: additionalData?.nutritionMasterData?.Dietary_Fiber_DV,
        addedSugar: additionalData?.nutritionMasterData?.Added_Sugars_DV,
        protein: additionalData?.nutritionMasterData?.Protein_DV,
        vitaminD: additionalData?.nutritionMasterData?.Vitamin_D_DV,
        calcium: additionalData?.nutritionMasterData?.Calcium_DV,
        iron: additionalData?.nutritionMasterData?.Iron_DV,
        pottasium: additionalData?.nutritionMasterData?.Potassium_DV,
      },
      aboutData: {
        totalCarbs:
          additionalData?.nutritionMasterData?.Total_Carbohydrate ||
          additionalData?.nutritionMasterData?.Total_Carbohydrate_mg ||
          additionalData?.nutritionMasterData?.Total_Carbohydrate_mcg,
        totalFat:
          additionalData?.nutritionMasterData?.Total_Fat ||
          additionalData?.nutritionMasterData?.Total_Fat_mg ||
          additionalData?.nutritionMasterData?.Total_Fat_mcg,
        saturatedFat:
          additionalData?.nutritionMasterData?.Saturated_Fat ||
          additionalData?.nutritionMasterData?.Saturated_Fat_mg ||
          additionalData?.nutritionMasterData?.Saturated_Fat_mcg,
        transFat:
          additionalData?.nutritionMasterData?.Trans_Fat ||
          additionalData?.nutritionMasterData?.Trans_Fat_mg ||
          additionalData?.nutritionMasterData?.Trans_Fat_mcg,
        cholestrol:
          additionalData?.nutritionMasterData?.Cholesterol ||
          additionalData?.nutritionMasterData?.Cholesterol_mg ||
          additionalData?.nutritionMasterData?.Cholesterol_mcg,
        sodium:
          additionalData?.nutritionMasterData?.Sodium ||
          additionalData?.nutritionMasterData?.Sodium_mg ||
          additionalData?.nutritionMasterData?.Sodium_mcg,
        sugars:
          additionalData?.nutritionMasterData?.Sugars ||
          additionalData?.nutritionMasterData?.Sugars_mg ||
          additionalData?.nutritionMasterData?.Sugars_mcg,
        dietaryFiber:
          additionalData?.nutritionMasterData?.Dietary_Fiber ||
          additionalData?.nutritionMasterData?.Dietary_Fiber_mg ||
          additionalData?.nutritionMasterData?.Dietary_Fiber_mcg,
        addedSugar:
          additionalData?.nutritionMasterData?.Added_Sugars ||
          additionalData?.nutritionMasterData?.Added_Sugars_mg ||
          additionalData?.nutritionMasterData?.Added_Sugars_mcg,
        protein:
          additionalData?.nutritionMasterData?.Protein ||
          additionalData?.nutritionMasterData?.Protein_mg ||
          additionalData?.nutritionMasterData?.Protein_mcg,
        vitaminD:
          additionalData?.nutritionMasterData?.Vitamin_D ||
          additionalData?.nutritionMasterData?.Vitamin_D_mg ||
          additionalData?.nutritionMasterData?.Vitamin_D_mcg,
        calcium:
          additionalData?.nutritionMasterData?.Calcium ||
          additionalData?.nutritionMasterData?.Calcium_mg ||
          additionalData?.nutritionMasterData?.Calcium_mcg,
        iron:
          additionalData?.nutritionMasterData?.Iron ||
          additionalData?.nutritionMasterData?.Iron_mg ||
          additionalData?.nutritionMasterData?.Iron_mcg,
        pottasium:
          additionalData?.nutritionMasterData?.Potassium ||
          additionalData?.nutritionMasterData?.Potassium_mg ||
          additionalData?.nutritionMasterData?.Potassium_mcg,
        energy:
          additionalData?.nutritionMasterData?.Energy ||
          additionalData?.nutritionMasterData?.Energy_mg ||
          additionalData?.nutritionMasterData?.Energy_mcg,
        serving_per:
          additionalData?.nutritionMasterData?.Serving_Per_Container ||
          additionalData?.nutritionMasterData?.Serving_Per_Container_mg ||
          additionalData?.nutritionMasterData?.Serving_Per_Container_mcg,
        serving_size:
          additionalData?.nutritionMasterData?.Serving_Size ||
          additionalData?.nutritionMasterData?.Serving_Size_mg ||
          additionalData?.nutritionMasterData?.Serving_Size_mcg,
      },
    };
  }

  function scrollToMyDiv() {
    scrollToChartRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    loadFilters()
  }, [product])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!productDataLoading && !productDataError && product?.data !== null && (
        <Helmet>
          <title>{capitalizeFirstChar(product?.productName)}</title>
          <meta
            name="description"
            content={`${capitalizeFirstChar(
              product?.productName
            )}: nutrition, ingredients, health rating, & carbon footprint.`}
          />
          <meta
            name="title"
            content={capitalizeFirstChar(product?.productName)}
          />
        </Helmet>
      )}

      <section id="productDetailPage">

        {!productDataLoading && !productDataError && product?.data !== null && (
          <div className="banner">
            <div className="container">
              <ProductBanner product={product} scrollref={scrollToMyDiv} />
            </div>
          </div>
        )}

        {!localStorage.getItem("selectedFilters") && <Preference />}

        {!productDataLoading &&
          !productOtherDataLoading &&
          product?.data !== null && (
            <ProductInfoTabs
              obj={obj}
              product={product}
              additionalData={additionalData}
              scrollToChartRef={scrollToChartRef}
            />
          )}

        {!productOtherDataLoading &&
          !productDataLoading &&
          product?.data !== null && (
            <Suspense fallback={<Loader />}>
              <Alternative product={product} />
            </Suspense>
          )}

        {!productOtherDataLoading &&
          !productDataLoading &&
          product?.data !== null && (
            <Suspense fallback={<Loader />}>
              <ProductPairing product={product} />
            </Suspense>
          )}

        <MoreOptions />

        {productDataLoading && <Loader />}
      </section>
    </>
  );
};

export default ProductDetailPage;
