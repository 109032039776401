import React, { useState } from "react";
import { Modal } from "antd";
import "./index.scss";
import { submitBug } from "../../APIs/functions";

const BugReport = ({ open, setOpen, productNameURL }) => {
  const [bugReport, setBugReport] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};

    if (!bugReport) {
      errors.bugReport = "Please provide a description of the issue";
    }

    if (email !== "" && !/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please provide a valid email address";
    }

    if (Object.keys(errors).length === 0) {
      const submitReport = async () => {
        const res = await submitBug({
          description: bugReport,
          productCode: `/p/${productNameURL}`,
          createdAt: Date.now(),
        });
        if (Object.keys(res).length > 0) {
           setSubmitted(true);
           setTimeout(() => {
             setOpen(false);
             setEmail('');
             setErrors({});
             setBugReport('');
           }, 2000);
        }
      };
      submitReport();
    } else {
      setErrors(errors);
    }
  };

  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <div className="bug-report-form">
          <h4>📝 Report an issue </h4>
         {!submitted ? <form onSubmit={handleSubmit}>
            <label htmlFor="bugReport"> Tell us about the issue </label>
            <textarea
              id="bugReport"
              name="bugReport"
              value={bugReport}
              onChange={(event) => setBugReport(event.target.value)}
              required
              cols="20"
              rows="5"
            ></textarea>
            <label htmlFor="email">
              We may want to follow up. Enter your email if you’re happy for us to
              contact you.
            </label>
            <input
              id="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              type="email"
            />
            {errors.email && (
              <div className="error-message text-danger">{errors.email}</div>
            )}
            <button type="submit"> Submit </button>
          </form> : <p className="form-submitted"> Thank you for helping us improve! </p>}
        </div>
      </Modal>
    </>
  );
};

export default BugReport;
