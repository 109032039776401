import React from "react";
import {
  valueFilters,
  dietFilters,
  foodAllergyFilters,
  nutritionFilters,
  ingredientsFilter,
  sustainFilters,
} from "./data";
import { Collapse } from "antd";
import "./index.scss";
import { Helmet } from "react-helmet";

const { Panel } = Collapse;

const Glossary = () => {
  return (
    <>
      <Helmet>
         <title>
          Glossary of Dietary Filters & Terms | GreenChoice
        </title>
        <meta
          name="description"
          content="Our 90+ dietary filters defined – covering diets, food allergies, lifestyle values, ingredient preferences, nutrient goals, & sustainability."
        />
        <meta
          name="title"
          content="Glossary of Dietary Filters & Terms | GreenChoice"
        />
      </Helmet>
      <section id="glossary">
        <div className="banner">
          <div className="container">
            <h1> Glossary of filters & terms </h1>
            <p>
              We’re on a mission to empower conscious consumption by making it
              easy to shop for healthy, climate-friendly food and drink. Sometimes
              our dietary filters can be a bit technical and you might have a
              question about how they’re defined. We’ve compiled a list of
              definitions for all our shopping filters, so you can know exactly
              what you’re getting when you use them.{" "}
            </p>
            <p>
              <strong>Still have a question? </strong>
              Message us using the chat box in the bottom right corner of this
              page.
            </p>
          </div>
        </div>
        <div className="table-of-contents">
          <div className="container">
            <h2>Table of Contents</h2>
            <ul>
              <li>
                <a href="#value-filters">Values filters</a>
              </li>
              <li>
                <a href="#diet-filters">Diet filters</a>
              </li>
              <li>
                <a href="#food-allergy-filters">Food allergy filters</a>
              </li>
              <li>
                <a href="#nutrition-filters">Nutrition filters</a>
              </li>
              <li>
                <a href="#ingredient-filters">Ingredient filters</a>
              </li>
              <li>
                <a href="#sustain-filters">Sustainability filters</a>
              </li>
            </ul>
            <div className="filter-wraps" id="values-filters">
              <h3 className="text-center">Values filters</h3>
              <Collapse accordion>
                {valueFilters.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div className="filter-wraps" id="diet-filters">
              <h3 className="text-center">Diet filters</h3>
              <Collapse accordion>
                {dietFilters.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div className="filter-wraps" id="food-allergy-filters">
              <h3 className="text-center">Food allergy filters</h3>
              <Collapse accordion>
                {foodAllergyFilters.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div className="filter-wraps" id="nutrition-filters">
              <h3 className="text-center"> Nutrition filters</h3>
              <Collapse accordion>
                {nutritionFilters.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div className="filter-wraps" id="ingredient-filters">
              <h3 className="text-center"> Ingredients filters</h3>
              <Collapse accordion>
                {ingredientsFilter.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div className="filter-wraps" id="sustain-filters">
              <h3 className="text-center"> Sustainability filters </h3>
              <Collapse accordion>
                {sustainFilters.map((item) => {
                  return (
                    <Panel header={item.title} key={item?.id}>
                      <p>{item.content}</p>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Glossary;
