import './index.scss';
function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found-container">
        <h1 className="not-found-heading">Page Not Found</h1>
        <p className="not-found-text">Sorry, it looks like the QR code you scanned is invalid or has expired.</p>
        <div className="d-flex align-items-center">
            <a className="not-found-link" href="/greenscore"> GreenScore® rating </a>
            <a className="not-found-link" href="/glossary"> Glossary of filters </a>
        </div >
      </div>
    </div>
  );
}

export default NotFound;
