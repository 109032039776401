import { Progress } from "antd";
import { getRadialColor } from "../../Utils/CommonFunctions";
import { handleProductCardClick } from "../../Gtag";
import placeHolderImg from "../../Assets/Images/placeholder.png";
import './index.scss'

const ProductCard = ({ carousel, product }) => {
  const url = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  }

  const client = new URL(window.location.href);
  const hostnameParts = client.hostname.split('.');
  const store = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';

  let productUrl = ""
  let productName = ""
  let productImg = ""
  let totalGS = ""

  if (store === "" || store === "qrdemo" || store === undefined) {
    productUrl = product?.productNameURL
    productName = product?.productName
    productImg = product?.productImage
    totalGS = product?.totalGs
  } else {
    productUrl = product?.productNameURL ? product?.productNameURL : url(product['Retailer Product Name'])
    productName = product['Retailer Product Name']
    productImg = product?.mainProductImage
    totalGS = product?.totalGs
  }

  return (
    <a href={`/p/${productUrl}`}>
      <div className="product-card card" onClick={() => handleProductCardClick(carousel === "similar" ? 'Product pairings' : 'Better-for-you', productName)}>
        <Progress
          type="circle"
          percent={Math.round(totalGS)}
          size={42}
          className="fw-bold text-primary pt-1"
          strokeWidth="11"
          strokeLinecap="round"
          format={(percent) => percent + ""}
          strokeColor={`${getRadialColor(Math.round(totalGS))}`}
        />
        <img className="product-img" src={productImg === "" || productImg === null || productImg === undefined ? placeHolderImg : productImg} alt={productName} />
        <p className="product-title">{productName?.toLowerCase()}</p>
      </div>
    </a>
  );
};

export default ProductCard;
