import React from "react";
import { Tabs } from "antd";
import ProductInfoTab from "../ProductInfo";
import Chart from "../Chart";
import './index.scss'

const ProductInfoTabs = ({
  obj,
  product,
  additionalData,
  scrollToChartRef,
}) => {

  const searchParams = new URLSearchParams(window.location.search);
  const nutritionData = searchParams.get('nutrition');
  const processingData = searchParams.get('processing');
  const foodSafetyData = searchParams.get('foodsafety');
  const environmentData = searchParams.get('environment');

  const items = [
    {
      key: "1",
      label: "GreenScore®",
      children: (
        <Chart
          product={product}
          additionalData={additionalData}
          customRef={scrollToChartRef}
          nutritionData={nutritionData}
          processingData={processingData}
          foodSafetyData={foodSafetyData}
          environmentData={environmentData}
        />
      ),
    },
    {
      key: "2",
      label: "Dietary",
      children: <ProductInfoTab product={product} tableData={obj} />,
    },
  ];
  return (
    <div className="pdp-info-tabs">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default ProductInfoTabs;
