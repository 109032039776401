import React from 'react'
import { useFilters } from '../../Context/FiltersContext'
import './index.scss'

const ToggleFilter = ({ filter, category }) => {

    const { selectedFilters, toggleSwitch } = useFilters()

    const id = Object.values(filter)[1]

    const handleChange = () => {
        toggleSwitch(category.toLowerCase(), id)
    }

    return (
        <div className="col-md-6">
            <div className='toggle-filter-wrap'>
                <div className="form-check form-switch mb-3">
                    <input onChange={handleChange}
                        value={category.toLowerCase() + "_" + id}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={selectedFilters.hasOwnProperty(category.toLowerCase() + "_" + id)}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{Object.values(filter)[0]}</label>
                </div>
            </div>
        </div>
    )
}

export default ToggleFilter