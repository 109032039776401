import React from "react";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { handleCreateProfile } from "../../Gtag";

const Preference = () => {
  const currentLocation = useLocation();

  const returnUrl = currentLocation.pathname + currentLocation.search;

  return (
    <div className="preference-setup-wrapper d-flex flex-column mb-4">
      <h3 className="text-center text-heading">
        Know if a food matches your dietary needs in seconds!
      </h3>
      <p className="text-paragraph">
        Looking for No Added Sugar? Vegan? Low Sodium? Low FODMAP? No Stevia?
        etc.
      </p>
      <Link to={`/preferences-setup?returnUrl=${encodeURIComponent(returnUrl)}`} onClick={() => handleCreateProfile()} className="pref-set-up-btn mx-auto">
        CREATE MY DIETARY PROFILE
      </Link>
    </div>
  );
};

export default Preference;
