import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import allergyIcon from "../../Assets/Images/Svgs/allergy.svg";
import ingredientIcon from "../../Assets/Images/Svgs/ingredients.svg";
import dietMatchIcon from "../../Assets/Images/Svgs/dietMatch.svg";
import { capitalizeFirstChar } from "../../Utils/CommonFunctions";
import "./index.scss";
import DisclaimerModal from "../DisclaimerModal";
import BugReport from "../BugReport";
import { ReactSVG } from "react-svg";
import { handleDisclaimerClicked, handleTabsClicked } from "../../Gtag";

const { TabPane } = Tabs;

const ProductInfoTab = ({ tableData, product }) => {
  const {
    allergyConflicts,
    ingredientConflicts,
    dietCompliance,
    ingredientList,
    productName,
    productNameURL,
  } = product;
  const [allergy, setAllergy] = useState([]);
  const [ingredientsArr, setIngredientsArr] = useState([]);
  const [dietArr, setDietArr] = useState([]);

  const [open, setOpen] = useState(false);
  const [bugReportOpen, setBugReportOpen] = useState(false);

  useEffect(() => {
    typeof allergyConflicts !== "undefined" &&
      setAllergy(allergyConflicts.split(","));
    typeof ingredientConflicts !== "undefined" &&
      setIngredientsArr(ingredientConflicts.split(","));
    typeof dietCompliance !== "undefined" &&
      setDietArr(dietCompliance.split(","));
  }, []);

  return (
    <div className="pdpTabs">
      <div className="container">
        <div className="factsTab">
          {/* <h3> The overview </h3> */}
          <div>
            <div>
              <h4>
                <ReactSVG className="svg-wrapper" src={allergyIcon} />
                Allergens found
              </h4>
              <ul>
                {allergy[0] !== "" ? (
                  allergy.map((item, index) => {
                    return <li key={index}>{capitalizeFirstChar(item)}</li>;
                  })
                ) : (
                  <li>None</li>
                )}
              </ul>
            </div>
            <div>
              <h4>
                <ReactSVG className="svg-wrapper" src={dietMatchIcon} />
                Diet matches
              </h4>
              <ul>
                {dietArr[0] !== "" ? (
                  dietArr.map((item, index) => {
                    return (
                      <li className="btn-green" key={index}>
                        {capitalizeFirstChar(item)}
                      </li>
                    );
                  })
                ) : (
                  <li>None</li>
                )}
              </ul>
            </div>
            <div>
              <h4>
                <ReactSVG className="svg-wrapper" src={ingredientIcon} />
                Ingredients of concern
              </h4>
              <ul>
                {ingredientsArr[0] !== "" ? (
                  ingredientsArr.map((item, index) => {
                    return <li key={index}>{capitalizeFirstChar(item)}</li>;
                  })
                ) : (
                  <li>None</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* <Tabs defaultActiveKey="1" centered={true} size={'large'}>
          <TabPane tab="Overview" key="1" onClick={() => handleTabsClicked('Overview tab clicked')}>
              
          </TabPane>
          <TabPane tab="Nutrition" key="2" onClick={() => handleTabsClicked('Nutrition tab clicked')}>
              <div className="nutriTab">
                  <h3>The nutrition</h3>
                  <div className="nutrition_facts_tab_chart">
                      <div>
                        <div className='d-flex align-items-start justify-content-between'>
                            <h3 className='d-flex flex-column'>
                              <span>Calories</span>
                              <span>Per serving</span>
                            </h3>
                            <p><b>{typeof tableData?.aboutData.energy?tableData?.aboutData?.energy:""}</b></p>
                        </div>
                        <div className='d-flex align-items-start justify-content-between'>
                           <h3 className='d-flex flex-column'>
                            <span> Serving size</span>
                            <span> {typeof tableData?.aboutData.serving_per?tableData?.aboutData?.serving_per:""} servings per container </span>
                           </h3>
                           <p><b>{typeof tableData?.aboutData.serving_size?tableData?.aboutData?.serving_size:""}</b></p>
                        </div>
                      </div>
  
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Per serving</th>
                              <th scope="col">%DV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="main-tr">
                              <td>Total fat </td>
                              <td>{typeof tableData?.aboutData?.totalFat !== 'undefined' && tableData?.aboutData?.totalFat!==null?tableData?.aboutData?.totalFat+"g":"-"}</td>
                              <td>{typeof tableData?.dv.totalFat !== 'undefined' && tableData?.dv.totalFat!==null?tableData?.dv.totalFat+"%":"-"}</td>
                            </tr>
                            <tr className="sub-tr">
                              <td>Saturated fat</td>
                              <td>{typeof tableData?.aboutData?.saturatedFat !== 'undefined' && tableData?.aboutData?.saturatedFat!==null?tableData?.aboutData?.saturatedFat+"g":"-"}</td>
                              <td>{typeof tableData?.dv.saturatedFat !== 'undefined' && tableData?.dv.saturatedFat!==null?tableData?.dv.saturatedFat+"%":"-"}</td>
                            </tr>
                            <tr className="sub-tr">
                              <td>Trans fat</td>
                              <td>{typeof tableData?.aboutData.transFat !== 'undefined' && tableData?.aboutData.transFat!==null?`${tableData?.aboutData.transFat}g`:"-"}</td>
                              <td>{typeof tableData?.dv?.transFat !== 'undefined' && tableData?.dv?.transFat!==null?tableData?.aboutData?.transFat:"-"}%</td>
                            </tr>
                            <tr>
                              <td>Cholestrol</td>
                              <td>{typeof tableData?.aboutData.cholestrol !=='undefined' &&tableData?.aboutData.cholestrol!==null ? `${tableData?.aboutData.cholestrol}mg`:"-"}</td>
                              <td>0%</td>
                            </tr>
                            <tr>
                              <td>Sodium</td>
                              <td>{typeof tableData?.aboutData.sodium !== 'undefined' && tableData?.aboutData.sodium!==null?tableData?.aboutData.sodium+"mg":"-"}</td>
                              <td>{typeof tableData?.dv.sodium !== 'undefined' && tableData?.dv.sodium!==null?tableData?.dv.sodium+"%":"-"}</td>
                            </tr>
                            <tr className="main-tr">
                              <td>Total Carb</td>
                              <td>{typeof tableData?.aboutData.totalCarbs !== 'undefined' && tableData?.aboutData.totalCarbs!==null?tableData?.aboutData.totalCarbs+"g":"-"}</td>
                              <td>{typeof tableData?.dv.totalCarbs !== 'undefined' && tableData?.dv.totalCarbs!==null?tableData?.dv.totalCarbs+"%":"-"}</td>
                            </tr>
                            <tr className="sub-tr">
                              <td>Dietary fiber</td>
                              <td>{typeof tableData?.aboutData.dietaryFiber !== 'undefined' && tableData?.aboutData.dietaryFiber!==null?tableData?.aboutData.dietaryFiber+"mg":"-"}</td>
                              <td>{typeof tableData?.dv.dietaryFiber !== 'undefined' && tableData?.dv.dietaryFiber!==null?tableData?.dv.dietaryFiber+"%":"-"}</td>
                            </tr>
                            <tr className="sub-tr">
                              <td>Total Sugars</td>
                              <td>{typeof tableData?.aboutData.sugars !== 'undefined' && tableData?.aboutData.sugars!==null?tableData?.aboutData.sugars+"g":"-"}</td>
                              <td>{typeof tableData?.dv.sugars !== 'undefined' && tableData?.dv.sugars!==null?tableData?.dv.sugars+"%":"-"}</td>
                            </tr>
                            <tr className="sub-tr">
                              <td>Includes added sugar</td>
                              <td>{typeof tableData?.aboutData.addedSugar !== 'undefined' && tableData?.aboutData.addedSugar!==null?tableData?.aboutData.addedSugar+"g":"-"}</td>
                              <td>{typeof tableData?.dv.addedSugar !== 'undefined' && tableData?.dv.addedSugar!==null?tableData?.dv.addedSugar+"%":"-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
  
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Per serving</th>
                              <th scope="col">%DV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Protein </td>
                              <td>{typeof tableData?.aboutData.protein !== 'undefined' && tableData?.aboutData.protein!==null?tableData?.aboutData.protein+"g":"-"}</td>
                              <td>{typeof tableData?.dv.protein !== 'undefined' && tableData?.dv.protein!==null?tableData?.dv.protein+"%":"-"}</td>
                            </tr>
                            <tr>
                              <td>Vitamin D</td>
                              <td>{typeof tableData?.aboutData.vitaminD !== 'undefined' && tableData?.aboutData.vitaminD!==null?tableData?.aboutData.vitaminD+"mcg":"-"}</td>
                              <td>{typeof tableData?.dv.vitaminD !== 'undefined' && tableData?.dv.vitaminD!==null?tableData?.dv.vitaminD+"%":"-"}</td>
                            </tr>
                            <tr>
                              <td>Calcium</td>
                              <td>{typeof tableData?.aboutData.calcium !== 'undefined' && tableData?.aboutData.calcium!==null?tableData?.aboutData.calcium+"mg":"-"}</td>
                              <td>{typeof tableData?.dv.calcium !== 'undefined' && tableData?.dv.calcium!==null?tableData?.dv.calcium+"%":"-"}</td>
                            </tr>
                            <tr>
                              <td>Iron</td>
                              <td>{typeof tableData?.aboutData.iron !== 'undefined' && tableData?.aboutData.iron!==null?tableData?.aboutData.iron+"mg":"-"}</td>
                              <td>{typeof tableData?.dv.iron !== 'undefined' && tableData?.dv.iron!==null?tableData?.dv.iron+"%":"-"}</td>
                            </tr>
                            <tr>
                              <td>Potassium</td>
                              <td>{typeof tableData?.aboutData.pottasium !== 'undefined' && tableData?.aboutData.pottasium!==null?tableData?.aboutData.pottasium+"mg":"-"}</td>
                              <td>{typeof tableData?.dv.pottasium !== 'undefined' && tableData?.dv.pottasium!==null?tableData?.dv.pottasium+"%":"-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
              </div>
          </TabPane>
          <TabPane tab="Ingredients" key="3" onClick={() => handleTabsClicked('Ingredients tab clicked')}>
              <div className="ingredientsTab">
                  <h2>The ingredients 
                    <span className='d-block'>{capitalizeFirstChar(productName.split('-').join(' '))}
                    </span>
                  </h2>
                  <div>
                     <p>{capitalizeFirstChar(ingredientList)}</p>
                  </div>
              </div>
          </TabPane>
       </Tabs> */}

        <div
          className="disclaimer"
          onClick={() => handleDisclaimerClicked(productName)}
        >
          <p>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 3C6.17 3 6.3126 3.0576 6.4278 3.1728C6.543 3.288 6.6004 3.4304 6.6 3.6V6.015C6.6 6.185 6.5424 6.325 6.4272 6.435C6.312 6.545 6.1696 6.6 6 6.6C5.83 6.6 5.6874 6.5424 5.5722 6.4272C5.457 6.312 5.3996 6.1696 5.4 6V3.585C5.4 3.415 5.4576 3.275 5.5728 3.165C5.688 3.055 5.8304 3 6 3ZM6 7.8C6.17 7.8 6.3126 7.8576 6.4278 7.9728C6.543 8.088 6.6004 8.2304 6.6 8.4C6.6 8.57 6.5424 8.7126 6.4272 8.8278C6.312 8.943 6.1696 9.0004 6 9C5.83 9 5.6874 8.9424 5.5722 8.8272C5.457 8.712 5.3996 8.5696 5.4 8.4C5.4 8.23 5.4576 8.0874 5.5728 7.9722C5.688 7.857 5.8304 7.7996 6 7.8ZM6 9.53674e-07C5.17 9.53674e-07 4.39 0.1576 3.66 0.472801C2.93 0.788001 2.295 1.2154 1.755 1.755C1.215 2.295 0.7876 2.93 0.4728 3.66C0.158 4.39 0.0004 5.17 0 6C0 6.83 0.1576 7.61 0.4728 8.34C0.788 9.07 1.2154 9.705 1.755 10.245C2.295 10.785 2.93 11.2124 3.66 11.5272C4.39 11.842 5.17 11.9996 6 12C6.83 12 7.61 11.8424 8.34 11.5272C9.07 11.212 9.705 10.7846 10.245 10.245C10.785 9.705 11.2126 9.07 11.5278 8.34C11.843 7.61 12.0004 6.83 12 6C12 5.17 11.8424 4.39 11.5272 3.66C11.212 2.93 10.7846 2.295 10.245 1.755C9.705 1.215 9.07 0.787401 8.34 0.472201C7.61 0.157001 6.83 -0.00039959 6 9.53674e-07Z"
                fill="#4E4E4E"
              />
            </svg>
            <span>
              This information is for informational purposes only.
              <span
                className="text-decoration-underline cursor-pointer"
                onClick={() => setOpen(true)}
              >
                {" "}
                See our disclaimer
              </span>{" "}
              |{" "}
              <span
                className="text-decoration-underline cursor-pointer"
                onClick={() => setBugReportOpen(true)}
              >
                {" "}
                Report an issue
              </span>
            </span>
          </p>
        </div>
        {open && <DisclaimerModal open={open} setOpen={setOpen} />}
        {bugReportOpen && (
          <BugReport
            open={bugReportOpen}
            setOpen={setBugReportOpen}
            productNameURL={productNameURL}
          />
        )}
      </div>
    </div>
  );
};

export default ProductInfoTab;
