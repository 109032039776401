import React, { useEffect } from 'react'
import { useFilters } from '../../Context/FiltersContext'
import "./index.scss"
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCheckDouble, faSliders, faTimes } from '@fortawesome/free-solid-svg-icons'

const FilterSelector = () => {
    const { loadFilters, selectedFilters, getFilterLabelById, handleRemoveFilter, clearFilters } = useFilters()

    const navigate = useNavigate();

    useEffect(() => {
        loadFilters();
    }, [])


    return (
        <div className='filter-selector-wrap'>
            <ul>
                <li className='px-3' onClick={() => navigate("/preferences-setup")}>
                    {Object.keys(selectedFilters).length > 0 ?
                        <FontAwesomeIcon
                            icon={faSliders}
                            className='px-1'
                        />
                        : "Add Filter"}
                </li>
                {Object.keys(selectedFilters).map(item => {
                    return <li className='d-flex align-items-center' onClick={() => handleRemoveFilter(
                        item.split("_")[0], parseInt(item.split("_")[1])
                    )}>
                        <FontAwesomeIcon icon={faCheckCircle} color='#17B169' style={{ paddingBottom: "3px" }} />
                        <span className='me-2 ms-1'>{getFilterLabelById(item.split("_")[0], parseInt(item.split("_")[1]))}</span>
                        <FontAwesomeIcon
                            icon={faTimes}
                            color='#4e4e4e'
                            style={{ padding: "0 6px 2px 5px" }}
                        />
                    </li>
                })}
                {Object.keys(selectedFilters).length > 0 && <li className='px-3' onClick={clearFilters}> Reset Filters </li>}
            </ul>
        </div>
    )
}

export default FilterSelector